import levenshtein from 'fast-levenshtein'

const messageOldDatesRegex = /\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\.?\s\d{4}\s(à|a|•)\s\d{1,2}\s?:?\s?\d{2}/iu
const messageNormalDateRegex = /\b[\wéèàùçÉÈÀÙÇ]{1,10}\s?\.?\s?\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\s?\.?\s?(à|a|•)\s?\d{1,2}\s?:\s?\d{2}\b/iu
const messageWeekdaysRegex = /(lundi|mardi|mercredi|jeudi|vendredi|samedi|dimanche)\s\d{1,2}\s?:\s?\d{2}/iu
const messageTodayDatesRegex = /(Aujourd(’|')hui|Today)\s\d{1,2}\s?:\s?\d{2}/iu
const messageYesterdayDatesRegex = /(Yesterday|Hier|Avant\s?-?\s?hier)\s(?:à\s)?\d{1,2}\s?:\s?\d{2}(?:[ap]m)?/iu
const messageOrImessage = /^(iMessage|Message)\s?[\wéèàùçÉÈÀÙÇ]{1,2}$/iu
const isResponseRegex = /\d*\s*(réponses|réponse|answers|answer)/iu
const messageLuARegex = /\bLu\sà\s\d{1,2}\s?:\s?\d{2}\b/iu

export const allDateRegex = [
  messageOldDatesRegex,
  messageNormalDateRegex,
  messageWeekdaysRegex,
  messageTodayDatesRegex,
  messageYesterdayDatesRegex,
  messageLuARegex
]

// **********************
// initial messages handling
// **********************

// GoogleVision JSON structure
export const getMessagesFromWords = (words) => {
  if (!words || words.length === 0) return []

  // console.log('words', words)

  while (words.length > 0) {
    const firstWord = words[0]
    
    // Remove if the first word is "+" or if it starts with "Message" or "iMessage"
    if (
      firstWord.text.toLowerCase().startsWith('message') ||
      firstWord.text.toLowerCase().startsWith('imessage') ||
      firstWord.text === "+"
    ) {
      // console.log('skipping', firstWord.text);
      words.shift() // Remove the first word and continue looping
    } else {
      break // Exit loop if no more words to remove
    }
  }

  let Messages = []
  let currentMessage = null // Start with null to make sure no empty message gets pushed

  words.forEach((word, index) => {
    const xDifference = (index < words.length - 1) ? words[index + 1].x - word.x : 0
    const yDifference = index > 0 ? word.y - words[index - 1].y : 0

    // remove any imessage word
    if (
      word.text.toLowerCase() === 'imessage' ||
      word.text.toLowerCase() === '¡ message' ||
      word.text.toLowerCase() === '¡message' ||
      word.text.toLowerCase() === 'KO'
    ) {
      word.text = ''
    }

    // Skip the "+" or the "r" words entirely and reset current message if x > 40 (too far away to be the same message)
    if (
      (
        word.text.toLowerCase() === '+' ||
        word.text === 'r' ||
        word.text === 'c' ||
        word.text === 'KO'
      ) &&
      xDifference > 40
    ) {
      // Push current message if it exists
      if (currentMessage && currentMessage.text) {
        Messages.push(currentMessage)
      }
      
      currentMessage = null // Reset the message after "+" so it doesn't carry over
      return
    }

    if (!currentMessage) {
      // Start a new message
      currentMessage = {
        text: word.text,
        x: word.x,
        y: word.y,
        width: word.width,
        height: word.height
      }
    } else if ( yDifference > 60 ) {
      // If yDifference is significant, push current message and start a new one
      Messages.push(currentMessage)
      
      currentMessage = {
        text: word.text,
        x: word.x,
        y: word.y,
        width: word.width,
        height: word.height
      }
    } else {
      // Continue adding words to the current message
      currentMessage.text += ' ' + word.text

      // Adjust the bounding box if needed
      currentMessage.width = Math.max(currentMessage.width, word.x + word.width - currentMessage.x)
      currentMessage.height = Math.max(currentMessage.height, word.y + word.height - currentMessage.y)
    }
  })

  // Push the last message if it exists
  if (currentMessage && currentMessage.text) {
    Messages.push(currentMessage)
  }

  Messages = Messages.map(message => {
    if (
      message.text.toLowerCase().startsWith('message') ||
      message.text.toLowerCase().startsWith('imessage')
    ) {
      message.text = message.text.replace(/i?Message\s?/iu, '')
    }

    // Ensure the message does not start with a number and specified words
    // const startsWithPattern = /^\d+\s*(réponses|réponse|answers|answer)/iu
    // const endsWithPattern = /\.\.\.\s*\d*\s*(réponses|réponse|answers|answer)$/iu
    // const endingPattern = /\d*\s*(réponses|réponse|answers|answer)$/iu

    // if (
    //   !startsWithPattern.test(message.text) &&
    //   !endsWithPattern.test(message.text) &&
    //   endingPattern.test(message.text)
    // ) {
    //   message.text = message.text.replace(endingPattern, '').trim()
    // }

    if (/\d+\s*(réponse|réponses|answer|answers)$/i.test(message.text)) {
      message.text = message.text.replace(/\d+\s*(réponse|réponses|answer|answers)$/i, '').trim()
    }

    message.text = message.text.replace(/\s+,/giu, ',')
      .replace(/\s+\./gu, '.')
      .replace(/\s+%/giu, '%')
      .replace(/\s+\)/giu, ')')
      .replace(/\(\s+/giu, '(')
      .replace('<<', '«')
      .replace('>>', '»')
  
    return message
  })

  Messages = Messages.filter(message => {
    const lowerCaseText = message.text.toLowerCase()
    // console.log("Filtering message:", lowerCaseText) // Add this line for debugging
  
    const shouldKeep = (
      lowerCaseText.length > 1 &&
      lowerCaseText.trim() !== '' &&
      lowerCaseText !== 'ho' &&
      lowerCaseText !== 'fo' &&
      lowerCaseText.trim() !== '+' &&  // match only a standalone '+'
      lowerCaseText !== '¡ message' &&
      lowerCaseText !== '¡message' &&
      lowerCaseText !== 'non distribué' &&
      lowerCaseText !== 'distribué' &&
      !lowerCaseText.startsWith('À : ') &&
      !/^.{1,9}\+$/i.test(lowerCaseText) &&
      !/\d+\s*(réponse|réponses|answer|answers)$/i.test(lowerCaseText) &&
      !/^\s*lu à \d{1,2}:\d{2}\s*$/i.test(lowerCaseText) &&
      !lowerCaseText.includes('message lu') &&
      !lowerCaseText.trim().endsWith(' apr...')
    )
  
    // if (!shouldKeep) console.log("Filtered out:", lowerCaseText) // Log filtered out messages
    return shouldKeep
  })

  return Messages
}

export const reassembleMessages = (messages) => {
  if (!messages || messages.length === 0) return []

  const reassembledMessages = []
  let currentMessage = null
  const xTolerance = 50 // Define a tolerance range for x positions to be considered "within the same range"

  messages.forEach((message, index) => {
    const prevMessage = index > 0 ? messages[index - 1] : null

    if (
      prevMessage &&
      prevMessage.width > 500 && // Only check width of previous message
      (Math.abs(message.x - prevMessage.x) <= xTolerance || message.x === prevMessage.x) // x positions within tolerance or exactly equal
    ) {
      // Merge the current message with the previous one
      currentMessage.text += ' ' + message.text
      currentMessage.width = Math.max(currentMessage.width, message.x + message.width - currentMessage.x)
      currentMessage.height = Math.max(currentMessage.height, message.y + message.height - currentMessage.y)
    } else {
      // Push the current message if it exists and start a new message
      if (currentMessage) {
        reassembledMessages.push(currentMessage)
      }
      
      // Start a new message with the current message
      currentMessage = { ...message }
    }
  })

  // Push the last message if it exists
  if (currentMessage) {
    reassembledMessages.push(currentMessage)
  }

  return reassembledMessages
}

/**
 * Make sure to identify and separate the date from the regular message from the sender (due to processed image)
 * This function ensures the correct order of the messages before and after date splits.
 * 
 * @param {*} messages - for the messages from sender and receiver
 * @returns the messages separated by date and regular message in the correct order
 */
export const checkAndSeparateDates = (messages) => {
  const separatedMessages = []

  messages.forEach((messageObj) => {
    let remainingText = String(messageObj.text).trim()
    let lastYPosition = messageObj.y

    while (remainingText) {
      let dateMatch = null
      let matchIndex = -1
      let isTemporary = false

      // Loop through regexes to find first match
      for (const regex of allDateRegex) {
        const match = remainingText.match(regex)

        if (match) {
          dateMatch = match[0]
          matchIndex = remainingText.indexOf(dateMatch)

          // Check if the match is from today or yesterday regexes
          if (
            // regex === messageTodayDatesRegex ||
            // regex === messageYesterdayDatesRegex ||
            regex === messageLuARegex
          ) {
            isTemporary = true // Mark to exclude from final messages
          }

          break
        }
      }

      if (dateMatch && matchIndex !== -1) {
        const before = remainingText.substring(0, matchIndex).trim()
        const after = remainingText.substring(matchIndex + dateMatch.length).trim()

        // Add the text before the date if it exists
        if (before) {
          separatedMessages.push({
            text: before,
            x: messageObj.x,
            y: lastYPosition,
            width: messageObj.width,
            height: messageObj.height
          })
          lastYPosition += 10
        }

        // Insert the date itself if not marked as temporary
        if (!isTemporary) {
          separatedMessages.push({
            text: dateMatch.replace(' a ', ' à ').replace(/(\d{1,2})\.(\d{2})/, '$1:$2'),
            x: messageObj.x,
            y: lastYPosition,
            width: messageObj.width,
            height: messageObj.height
          })
          lastYPosition += 10
        }

        remainingText = after
      } else {
        // Add remaining text if no date match is found
        if (remainingText.trim()) {
          separatedMessages.push({
            text: remainingText.trim(),
            x: messageObj.x,
            y: lastYPosition,
            width: messageObj.width,
            height: messageObj.height
          })
        }
        remainingText = ''
      }
    }
  })

  // Return only messages not marked as temporary
  return separatedMessages.filter(message => message.text !== '')
}

/**
 * Filter messages to keep only those before a "+ " message and messages with Lu and Distribué (fr and en)
 * @param {Array} messages are all the messages extracted from the images
 * @returns {Array} filteredMessages are the messages to keep
 */
export const truncateConversation = (messages) => {
  let foundPlusMessage = false
  
  const filteredMessages = messages.filter((message) => {
    const messageText = message.text.trim()
    const wordCount = messageText.split(/\s+/).length

    // If a message starts with "+ ", mark the rest to be excluded
    if (
      (
        messageText.toLowerCase() === '+' ||
        messageText.toLowerCase().startsWith('+ ') ||
        messageText.toLowerCase().startsWith('distribué +') ||
        messageText.toLowerCase().startsWith('distribue +')
      ) && wordCount <= 10
    ) {
      foundPlusMessage = true
    }

    // Once a "+ " message is found, exclude subsequent messages
    if (foundPlusMessage) {
      console.log('foundPlusMessage', messageText);
      
      return false
    }

    // Keep all messages before the "+ " message
    return true
  })

  return filteredMessages // No need for Promise wrapping
}

// **********************
// dates handling
// **********************

const padZero = (num) => num < 10 ? `0${num}` : `${num}`
const padMilliseconds = (num) => num < 10 ? `00${num}` : num < 100 ? `0${num}` : `${num}`

export const formatDateString = (date) => {
  if (!(date instanceof Date) || isNaN(date)) {
    return null
  }

  const year = date.getUTCFullYear()
  const month = padZero(date.getUTCMonth() + 1)
  const day = padZero(date.getUTCDate())
  const hours = padZero(date.getUTCHours())
  const minutes = padZero(date.getUTCMinutes())
  const seconds = padZero(date.getUTCSeconds())
  const milliseconds = padMilliseconds(date.getUTCMilliseconds())

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00`
}

export const parseDateToTimestampz = (dateStr) => {
  const monthsMap = {
    // fr
    'janv': 0, 'févr': 1, 'mars': 2, 'avr': 3, 'mai': 4, 'juin': 5,
    'juil': 6, 'août': 7, 'sept': 8, 'oct': 9, 'nov': 10, 'déc': 11,

    'janvier': 0, 'février': 1, 'avril': 3, 'juillet': 6, 'août': 7,
    'septembre': 8, 'octobre': 9, 'novembre': 10, 'décembre': 11,

    // en
    'jan': 0, 'févr': 1, 'mars': 2, 'avr': 3, 'mai': 4, 'juin': 5,
    'juil': 6, 'août': 7, 'sept': 8, 'oct': 9, 'nov': 10, 'déc': 11,

    'january': 0, 'february': 1, 'april': 3, 'july': 6, 'august': 7,
    'september': 8, 'october': 9, 'november': 10, 'december': 11,
  }

  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  
  // Try to match using allDateRegex
  for (const regex of allDateRegex) {
    if (regex.test(dateStr)) {
      // Now determine the matched pattern and handle accordingly

      // If it's the messageOldDatesRegex (e.g., "12 novembre 2023 à 18:09")
      if (regex === messageOldDatesRegex) {
        const [day, month, yearPart, , time] = dateStr.split(' ')
        const year = yearPart ? parseInt(yearPart) : new Date().getFullYear()
        const monthIndex = monthsMap[month.replace('.', '')]
        const [hours, minutes] = time.replace(/\s/g, '').split(':')
        const date = new Date(Date.UTC(year, monthIndex, parseInt(day), parseInt(hours), parseInt(minutes), 0, 0))     

        return formatDateString(date);
      }

      // If it's the messageNormalDateRegex (e.g., "mar. 5 mai à 18:09")
      if (regex === messageNormalDateRegex) {
        const [, day, month, , time] = dateStr.split(' ')
        const monthIndex = monthsMap[month.replace('.', '')]
        // Use previous year if no year provided and month is after current month
        const year = monthIndex > currentMonth ? currentYear - 1 : currentYear

        const [hours, minutes] = time.split(':')
        const date = new Date(Date.UTC(year, monthIndex, parseInt(day), parseInt(hours), parseInt(minutes), 0, 0))
        
        return formatDateString(date)
      }

      if (regex === messageYesterdayDatesRegex) {
        const timePart = dateStr.match(/\d{1,2}\s?:\s?\d{2}/)[0].replace(/\s/g, '')
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1) // Go back 1 day
        const [hours, minutes] = timePart.split(':')
        yesterday.setUTCHours(parseInt(hours), parseInt(minutes), 0, 0)
        
        return formatDateString(yesterday)
      }
    }
  }

  return null
}

/**
 * Filters out "useless" parts of the message such as:
 * - Exact match for "iMessage"
 * - Ending with "Lu le ..." (case-insensitive) or "Distribué le ..." (case-insensitive)
 * The function removes the matching parts from the message but keeps the rest.
 *
 * @param {Array} messages - An array of message objects. Each message object should contain at least a `text` property.
 * @returns {Array} - The filtered array of messages with "useless" parts removed.
 */
export const handleRemovedUselessText = (messages) => {
  // Regex to detect 'Lu le ...' or 'Distribué le...' at the end of the message
  const luLeRegex = /\s?lu le\s.+$/i
  const distribueLeRegex = /\s?distribué le\s.+$/i

  // Filter and clean up the messages based on the conditions
  const filteredMessages = messages.map((message) => {
    let messageText = message.text.trim()

    // Remove 'iMessage' or 'Message' or 'réponses' texts
    if (
      messageText.toLowerCase() === 'imessage' ||
      messageText.toLowerCase() === 'message' ||
      /\d+\s*(réponse|réponses|answer|answers)$/i.test(messageText)
    ) {
      return null // Mark this message as null to filter it out later
    }

    // Remove the part that matches 'Lu le ...' or 'Distribué le ...'
    messageText = messageText.replace(luLeRegex, '').trim()
    messageText = messageText.replace(distribueLeRegex, '').trim()

    // Return the modified message if there's still text remaining
    return {
      ...message,
      text: messageText
    }
  }).filter(message => message && message.text !== '') // Filter out null or empty messages

  // Resolve the promise with the filtered messages
  return filteredMessages
}

export const handleAndroidMessagesEN = (messages) => {
  const mergedMessages = []
  let mergeWithNext = false
  let tempMessage = null

  // First pass: Merge "Received Sent by..." messages with the next date message
  messages.forEach((msg) => {
    const msgLw = msg.text.toLowerCase().trim()

    if (
      msgLw.startsWith('conversation with') ||
      msgLw.startsWith('conversation avec') ||
      msgLw.startsWith('type date')
     ) {
      return
    }

    if (msgLw.startsWith('received sent by')) {
      tempMessage = { ...msg, text: 'Received' }
      mergeWithNext = true
    } else if (mergeWithNext) {
      // Merge tempMessage with current message (assumed to contain the date)
      mergedMessages.push({
        ...tempMessage,
        text: `${tempMessage.text} ${msgLw}`,
        y: Math.min(tempMessage.y, msg.y),
        x: tempMessage.x,
      })
      mergeWithNext = false
      tempMessage = null
    } else {
      mergedMessages.push(msg)
    }
  })

  // Second pass: Add createdAt and isSenderMessage fields
  const cleanedMessages = []
  let lastMessageMeta = null

  mergedMessages.forEach((msg) => {
    const msgLw = msg.text.toLowerCase().trim()

    if ( msgLw.startsWith('sent') || msgLw.startsWith('received') ) {
      const [type, datePart, timePart] = msgLw.split(/\s+(\w+ \d{1,2}, \d{4})\s+(.+)/)
      const dateStr = `${datePart} ${timePart}`
      const date = new Date(dateStr)
      
      if ( isNaN(date.getTime()) ) {
        console.error('Error parsing date:', dateStr)
      } else {
        lastMessageMeta = {
          isSenderMessage: type === 'sent',
          createdAt: date.toISOString(),
        }
      }
    } else if (lastMessageMeta) {
      cleanedMessages.push({
        ...msg,
        ...lastMessageMeta,
      })

      lastMessageMeta = null
    }
  })

  return cleanedMessages
}

export const handleAndroidMessagesFR = (messages) => {
  const mergedMessages = []
  let mergeWithNext = false
  let tempMessage = null

  // Pre-pass for UTF-8 characters
  messages.forEach((msg) => {
    msg.text = msg.text.trim()
      .replace(/envoy\s?é/g, 'envoyé')
      .replace(/re\s?ç\s?u/g, 'reçu')
      .replace(/ç\s/g, 'ç')
      .replace(/\sé/g, 'é')
  })

  // First pass: Merge "Received Sent by..." messages with the next date message
  messages.forEach((msg) => {
    const msgLw = msg.text.toLowerCase()

    if (
      msgLw.startsWith('conversation avec') ||
      msgLw.startsWith('type date')
     ) {
      return
    }

    if (msgLw.startsWith('reçu envoyé par')) {
      tempMessage = { ...msg, text: 'Reçu' }
      mergeWithNext = true
    } else if (mergeWithNext) {
      // Merge tempMessage with current message (assumed to contain the date)
      mergedMessages.push({
        ...tempMessage,
        text: `${tempMessage.text} ${msgLw}`,
        y: Math.min(tempMessage.y, msg.y),
        x: tempMessage.x,
      })
      mergeWithNext = false
      tempMessage = null
    } else {
      mergedMessages.push(msg)
    }
  })

  // Second pass: Add createdAt and isSenderMessage fields
  const cleanedMessages = []
  let lastMessageMeta = null

  mergedMessages.forEach((msg) => {
    const msgLw = msg.text.toLowerCase()
    const match = msgLw
      .match(/\b(\d{1,2} \w+\.? \d{4})\s+(.+)/)

    if (match) {
      const [_, datePart, timePart] = match

      // Attempt to replace French month with English equivalent using a map
      const monthMap = {
        'janv': 'Jan', 'févr': 'Feb', 'mars': 'Mar', 'avr': 'Apr',
        'mai': 'May', 'juin': 'Jun', 'juil': 'Jul', 'août': 'Aug',
        'sept': 'Sep', 'oct': 'Oct', 'nov': 'Nov', 'déc': 'Dec',
      }
      const dateStr = datePart.replace(/\b(\w+)\.?/gi, (month) => monthMap[month.toLowerCase()] || month) + ' ' + timePart
      const date = new Date(dateStr)

      if (isNaN(date.getTime())) {
        console.error('Error parsing date:', dateStr)
      } else {
        lastMessageMeta = {
          isSenderMessage: msgLw.startsWith('envoyé'),
          createdAt: date.toISOString(),
        }
      }
    } else if (lastMessageMeta) {
      cleanedMessages.push({
        ...msg,
        ...lastMessageMeta,
      })

      lastMessageMeta = null
    }
  })

  return cleanedMessages
}

/**
 * Final step of the parsing : identify each message by its date
 * @param {Array} mergedMessages for the messages classified and merged (doublons)
 * @returns {Array} the messages with isDate bool and createdAt timestampz
 */
export const handleDateMessages = (mergedMessages) => {
  let currentDate = null
  let firstDateIndex = null

  mergedMessages = mergedMessages.map((message, index) => {
    message.createdAt = null // base

    const messageText = message.text.trim()

    // Check if the message matches one of the valid date regex patterns
    const messageIsAdate = messageOldDatesRegex.test(messageText) ||
                            messageNormalDateRegex.test(messageText) ||
                            messageTodayDatesRegex.test(messageText) ||
                            messageWeekdaysRegex.test(messageText) ||
                            messageYesterdayDatesRegex.test(messageText) ||
                            messageOrImessage.test(messageText)

    // If the message is a date
    if (messageIsAdate) {
      message.isDate = true // identify the message as a date
       
      // Replace any formatting issues in the time part of the message
      message.text = message.text
        .replace(/\s?:\s?/, ':').trim()
        .replace(/\s?\./g, '.')

      // Try to parse the date only if it's a valid date string
      currentDate = parseDateToTimestampz(message.text)

      if (currentDate) {
        // do not consider "Hier" or "Aujourd'hui" as a true date to keep
        message.createdAt = !messageYesterdayDatesRegex.test(message.text)
          ? currentDate
          : null

        if (firstDateIndex === null) {
          firstDateIndex = index
        }
      }
    }

    return message
  })
  
  // Resolve the promise with the updated messages
  return mergedMessages
}

// **********************
// responses messages
// **********************

export const checkIfResponse = (messages) => messages.map(message => {
  const regex = /\.\.\.\s+\d*\s*(réponses|réponse|answers|answer)/i
  const messageText = message.text.trim()

  // Check if the message ends with the specified pattern
  message.isResponse = regex.test(messageText)

  // If it's a response, remove the regex part from the message
  if (message.isResponse) {
    message.text = messageText.replace(regex, '').trim()
  }

  return message
})

// check if the response is followed by a message from the sender or receiver and adjust it
export function correctIsSenderMessage(messages) {
  for (let i = 1; i < messages.length; i++) {
    if (!messages[i].isSenderMessage) {
      let j = i - 1

      while (j >= 0 && messages[j].isResponse) {
        messages[i].isSenderMessage = false
        j--
      }
    }
  }

  // Handle previous messages if they need to be set to false
  for (let i = messages.length - 2; i >= 0; i--) {
    if (messages[i].isResponse && !messages[i + 1].isSenderMessage) {
      messages[i].isSenderMessage = false
    }
  }

  // Check if some sender messages were not forgotten
  for (let i = 1; i < messages.length; i++) {
    if (!messages[i].isSenderMessage && !messages[i].isResponse && messages[i].x > 195) {
      messages[i].isSenderMessage = true
    }
  }

  return messages
}

// **********************
// messages processing
// **********************

/**
 * 
 * @param {Array} messages for classified messages
 * @returns {Array} all the messages without doublons
 */
export const mergeEqualMessages = (messages, isPDFiPhone = false) => {
  if (messages.length === 0) return []

  const mergedEqualMessages = []
  let currentMessage = { ...messages[0] } // Start with a copy of the first message

  for (let i = 1; i < messages.length; i++) {
    const nextMessage = messages[i]

    // Merge messages that have the same y coordinate
    if ( currentMessage.y === nextMessage.y ) {
      currentMessage.text += ' ' + nextMessage.text
    } else {
    // otherwise it is a new message
      mergedEqualMessages.push(currentMessage)
      currentMessage = { ...nextMessage }
    }
  }

  // Push the last message
  mergedEqualMessages.push(currentMessage)

  mergedEqualMessages.map(msg => {
    msg.text = msg.text.replace(/\s(['’])\s/gi, '$1')
  })

  // Resolve the promise with the result
  return mergedEqualMessages
}

/**
 * 
 * @param {Array} messages for arrays for messages
 * @returns {Array} messages merged if they are separated by less than 20px
 */
export const mergeSeparatedMessages = (messages) => {
  if (messages.length === 0) return []

  const mergedSeparatedMessages = []
  let currentMessage = { ...messages[0] } // Start with a copy of the first message

  for (let i = 1; i < messages.length; i++) {
    const nextMessage = messages[i]

    // Check if the y difference is less than 17 (adjusted range)
    const yDifference = currentMessage.y - nextMessage.y
    if (yDifference > 0 && yDifference < 17) {
      currentMessage.text += ' ' + nextMessage.text
      currentMessage.y = nextMessage.y
    } else {
      mergedSeparatedMessages.push(currentMessage)
      currentMessage = { ...nextMessage }
    }
  }

  // Push the last message
  mergedSeparatedMessages.push(currentMessage)

  // Resolve the promise with the merged result
  return mergedSeparatedMessages
}

/**
 * 
 * @param {Array} extractedData for the data extracted from the images
 * @returns {Array} messages classified by sender, and correct json structure
 */
export const classifyMessages = (extractedData) => {
  const classifiedMessages = []
  let messageNumber = 0

  extractedData.forEach(page => {
    page.textItems.forEach(item => {
      const [x, y] = [item.transform[4], item.transform[5]]
      const isSenderMessage = x > 180 // If text width position is over 200, it's a sender message

      classifiedMessages.push({
        text: item.str,
        y,
        x,
        isSenderMessage,
        isDate: false,
        order: messageNumber++,
      })
    })
  })

  return classifiedMessages
}

// **********************
// submiting conversation
// **********************

// clean the whole conversation
export const handleSimilarMessage = (existingMessage, newMessage) => {
  const existingWords = existingMessage.split(/\s+/).length
  const newWords = newMessage.split(/\s+/).length

  // Check if the number of words is similar within a range of +/- 5 words
  const wordDifference = Math.abs(existingWords - newWords)
  if (wordDifference > 5) return false

  // Check if the first few words (to allow some tolerance) match
  const existingSnippet = existingMessage.split(/\s+/).slice(0, 10).join(' ')
  const newSnippet = newMessage.split(/\s+/).slice(0, 10).join(' ')

  return existingSnippet === newSnippet
}

export const isSimilarMessage = (messageA, messageB) => {
  const minSimilarity = 0.7 // Lower similarity threshold to 70%
  const timeThreshold = 24 * 60 * 60 * 1000 // 1 day in milliseconds

  const wordsA = messageA.text.split(/\s+/)
  const wordsB = messageB.text.split(/\s+/)

  // Define common phrases to ignore in similarity checks
  const commonPhrases = new Set(['bonne journée', 'pas de soucis', 'merci', 'bonne fin de journée'])

  // Filter out common phrases
  const filteredWordsA = wordsA.filter(word => !commonPhrases.has(word.toLowerCase()))
  const filteredWordsB = wordsB.filter(word => !commonPhrases.has(word.toLowerCase()))

  // Fuzzy word match function
  const fuzzyWordMatch = (word1, word2) => {
    const distance = levenshtein.get(word1.toLowerCase(), word2.toLowerCase())
    return distance / Math.max(word1.length, word2.length) <= 0.3 // Accept words with up to 30% difference
  }

  // Calculate similarity with fuzzy matching
  const calculateSimilarity = (words1, words2) => {
    let commonCount = 0
    words1.forEach(word1 => {
      if (words2.some(word2 => fuzzyWordMatch(word1, word2))) {
        commonCount++
      }
    })
    return commonCount / Math.min(words1.length, words2.length)
  }

  const similarity = calculateSimilarity(filteredWordsA, filteredWordsB)

  // Length ratio: Avoid treating smaller messages as duplicates if significantly shorter
  const lengthRatio = Math.min(filteredWordsA.length, filteredWordsB.length) / Math.max(filteredWordsA.length, filteredWordsB.length)

  // Check time difference
  const timeDifference = Math.abs(new Date(messageA.createdAt) - new Date(messageB.createdAt))

  // Refine similarity check: Ensure sufficient length ratio and similarity
  return similarity >= minSimilarity && timeDifference <= timeThreshold && lengthRatio > 0.5
}

const mergeConsecutiveIdenticalMessages = (conversation) => {
  const mergedMessages = []

  for (let i = 0; i < conversation.length; i++) {
    const currentMessage = conversation[i]
    const lastMergedMessage = mergedMessages[mergedMessages.length - 1]

    if (
      lastMergedMessage &&
      currentMessage.text === lastMergedMessage.text
    ) {
      continue
    }

    mergedMessages.push(currentMessage)
  }

  return mergedMessages
}

export const findSimilarMessages = (conversation) => {
  // Preprocess to merge consecutive identical date messages
  const conversationWithoutConsecutiveDateDuplicates = mergeConsecutiveIdenticalMessages(conversation)

  // Proceed with the similarity check as before
  const messagesToKeep = [...conversationWithoutConsecutiveDateDuplicates]

  for (let i = 0; i < messagesToKeep.length; i++) {
    for (let j = i + 1; j < messagesToKeep.length; j++) {
      const messageA = messagesToKeep[i]
      const messageB = messagesToKeep[j]

      if (isSimilarMessage(messageA, messageB)) {
        const messageAWords = messageA.text.split(/\s+/).length
        const messageBWords = messageB.text.split(/\s+/).length

        if (messageAWords >= messageBWords) {
          messagesToKeep.splice(j, 1)
          j--
        } else {
          messagesToKeep.splice(i, 1)
          i--
          break
        }
      }
    }
  }

  return messagesToKeep
}

export const assignDatesToAllMessages = (messages) => {
  const addTime = (date, minutes, seconds) => new Date(date.getTime() + minutes * 60000 + seconds * 1000)

  // Initialize the last known date
  let lastDate = null
  const now = new Date()

  // Ensure at least one `createdAt` date is set
  const hasDate = messages.some(message => message.createdAt)
  if (!hasDate && messages.length > 0) {
    messages[0].createdAt = now.toISOString()
  }

  // Forward pass: Assign dates in ascending order
  messages.forEach((message, index) => {
    if (message.isDate && message.createdAt) {
      lastDate = new Date(message.createdAt)
    } else if (lastDate) {
      lastDate = addTime(lastDate, 1, 2) // Add 1 minute and 2 seconds
      message.createdAt = lastDate.toISOString()
    }
  })

  // Reverse pass: Assign dates in descending order
  lastDate = null // Reset lastDate for backward pass
  let reverseCounter = 2
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i]

    if (message.isDate && message.createdAt) {
      lastDate = new Date(message.createdAt)
      reverseCounter = 2
    } else if (lastDate) {
      lastDate = addTime(lastDate, 0, -reverseCounter) // Subtracting seconds
      message.createdAt = lastDate.toISOString()
      reverseCounter += 2 // Increment counter
    }
  }

  // Final pass: Remove older `isDate = true` messages if consecutive
  for (let i = 1; i < messages.length; i++) {
    const currentMessage = messages[i]
    const previousMessage = messages[i - 1]

    if (currentMessage.isDate && previousMessage.isDate) {
      // Remove the older of two consecutive date messages
      if (new Date(currentMessage.createdAt) < new Date(previousMessage.createdAt)) {
        messages.splice(i, 1)
        i--
      } else {
        messages.splice(i - 1, 1)
        i--
      }
    }
  }

  return messages
}

export const correctYearMessagesDates = (messages) => {
  let lastDate = null

  // Check if at least one message has a year in the date text
  const hasFullYearDate = messages.some(message => messageOldDatesRegex.test(message.text))

  // If no messages have a full year date format, return as no correction is needed
  if (!hasFullYearDate) return messages

  // Loop through messages in reverse order to apply logical year adjustments
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i]

    if (!message.createdAt) continue

    if (message.createdAt) {
      const currentDate = new Date(message.createdAt)
      
      // Logic to adjust the date based on month/year comparison with lastDate
      if (
        lastDate &&
        (
          (currentDate.getUTCMonth() > lastDate.getUTCMonth() &&
           currentDate.getUTCFullYear() >= lastDate.getUTCFullYear()) ||
          (currentDate.getUTCMonth() === lastDate.getUTCMonth() &&
           currentDate.getUTCFullYear() > lastDate.getUTCFullYear() &&
           messageNormalDateRegex.test(message.text))
        )
      ) {
        // Adjust year if condition is met
        currentDate.setUTCFullYear(currentDate.getUTCFullYear() - 1)
        message.createdAt = currentDate.toISOString() // Update the createdAt date
      }

      // Update lastDate to the current (or adjusted) date
      lastDate = new Date(message.createdAt)
    }
  }

  // Return the modified array with all messages intact
  return messages
}