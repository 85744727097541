import {
  useRecordContext,
  useSaveContext,
  DeleteButton,
  SaveButton,
  Toolbar,
} from 'react-admin'
import { useMediaQuery } from '@mui/material'

import { getRole } from '../common/roles'
import { getItemFromLocalStorage } from '../common/LocalStorage'

import CircularProgress from '@mui/material/CircularProgress'

const UserEditToolbar = ({autosave, gradeRole, isDisabled, selectedEventId, props}) => {
  const record = useRecordContext(props)
  const localCurrentYear = getItemFromLocalStorage('localCurrentYear') || process.env.REACT_APP_CURRENT_YEAR_ID
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  return (
    <>
      { record?.id && (
        <div style={{ position: 'absolute', top: 20, right: 60 }}>
          { autosave ? <CircularProgress /> : <SaveButton
            label=""
            disabled={selectedEventId ? false : isDisabled}
            alwaysEnable={!!selectedEventId}
          /> }
        </div>
      )}

      <Toolbar
        sx={ isMobile
          ? { width: '80% !important', margin: '0 auto', borderRadius: '8px', mb: 5, }
          : { ml: '10px', mr: '10px', borderRadius: '8px', mb: 2, }
        }
      >
        <SaveButton
          disabled={selectedEventId ? false : isDisabled}
          alwaysEnable={!!selectedEventId}
        />

        { gradeRole >= getRole('responsable') && (
          <DeleteButton
            style={{ position: 'absolute', right: 10 }}
            disabled={ localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
          />
        )}
      </Toolbar>
    </>
  )
}

export default UserEditToolbar