import * as React from 'react'
import { Box } from '@mui/material'
import { CreateDialog } from '@react-admin/ra-form-layout'

import LeadForm from './LeadForm'

const LeadCreate = () => (
  <CreateDialog
    title="Ajouter un nouveau contact"
    maxWidth="md"
    sx={{
      '& .MuiDialogTitle-root': {
        backgroundColor: '#d7eae8',
      },
    }}
  >
    <Box sx={{ p: 2 }}>
      <LeadForm action="create" />
    </Box>
  </CreateDialog>
)

export default LeadCreate;
