import { getValidPhone } from '../common/Formating'

export const validateAtLeastOneName = (value, allValues) => (!value && !allValues.first_name && !allValues.last_name)
  ? 'Veuillez renseigner au moins le nom ou le prénom.'
  : undefined

export const validateAtLeastOneContactInfo = (value, allValues) => (!value && !allValues.phone && !allValues.email)
  ? 'Veuillez renseigner au moins un moyen de contact (téléphone ou email).'
  : undefined

export const validatePhone = (value, allValues) => {
    const phone = getValidPhone(value)
    return (value !== null && phone === null) ? 'Veuillez renseigner un numéro de téléphone valide.' : undefined
}

export const validateCenter = (value, allValues) => (!value && !allValues.center_id)
  ? 'Veuillez associer le contact à votre centre'
  : undefined
