import { useState, useEffect } from 'react'
import {
  useListContext,
  TopToolbar,
  useRefresh,
  useNotify,
  useUpdateMany,
} from 'react-admin'
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material'

import sendMail from '../common/SendMail'
import { supabaseClient } from '../supabase'
import toTimeZone from '../common/toTimeZone'
import { ImportBrochuresButton } from './importContacts'
import AssignationDialog from '../common/AssignationDialog'
import createNotification from '../common/createNotification'

export const InboundContactActions = (props) => {
  const [status, setStatus] = useState('new')
  const { selectedIds, sort, filterValues, setSort, setFilters } = useListContext()

  const handleStatusChange = (event) => {
    const newStatus = event.target.value
    setStatus(newStatus)

    const newFilters = { status: newStatus }
    setFilters(newFilters, { ...filterValues }, false)

    if (newStatus === 'new') {
      setSort({ field: 'requested_date', order: 'DESC' })
    } else {
      if (sort.field !== 'updated_at') {
        setSort({ field: 'updated_at', order: 'DESC' })
      }
    }
  }

  return (
    <TopToolbar
      sx={{ 
        display: 'flex !important',
        flexDirection: 'row',
        justifyContent: status === 'new' ? 'flex-start' : 'flex-end',
        alignItems: 'center',
        position: 'relative !important',
        width: '40%',
        paddingBottom: 0,
        minHeight: '60px',
        padding: selectedIds.length > 0 ? '20px 0 70px 5px !important' : null
      }}
    >
      {status === 'new' && (
        <ImportBrochuresButton sx={{ marginRight: '8px' }} />
      )}
      <FormControl sx={{ minWidth: 150 }}>
        <InputLabel id="status-select-label" sx={{ mt: -1.5 }}>Statut</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={status}
          onChange={handleStatusChange}
          label="Statut"
          variant="outlined"
        >
          <MenuItem value="new">Nouvelles demandes</MenuItem>
          <MenuItem value="processing">En attente</MenuItem>
          <MenuItem value="done">Traitées</MenuItem>
        </Select>
      </FormControl>
    </TopToolbar>
  )
}

export const AssignBulkActionButtons = props => {
  const refresh = useRefresh()
  const notify = useNotify()
  const [updateMany, { error }] = useUpdateMany()
  const [pubeurInfo, setPubeurInfo] = useState(null)

  const performAssignation = async (leads, pubeurId) => {
    updateMany(
      'leads',
      {
        ids: leads,
        data: {
          assigned_pubeur: pubeurId ?? null,
          updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null
        },
      },
      {
        onSuccess: (data) => {
          refresh()
          notify('Modification pubeur affectuée', { type: 'success' })
        },
        onError: (error) => {
          notify(`Erreur affectation pubeur : ${error.message}`, { type: 'error' })
        },
    })
    
    // send mail informing of the assignation
    if ( pubeurId ) {
      const fetchPubeurInfo = async () => {
        const {data, error} = await supabaseClient
          .from('profiles')
          .select()
          .eq('id', pubeurId)
          .single()

        if (error) {
          console.error('Error fetching pubeur info: ', error)
          return null
        }

        return await data
      }

      const fetchLeadInfo = async lead_id => {
        const {data, error} = await supabaseClient
          .from('leads')
          .select()
          .eq('id', lead_id)
          .single()

        if (error) {
          console.error('Error fetching pubeur info: ', error)
          return null
        }

        return await data
      }

      try {
        const pubeurInfo = await fetchPubeurInfo()

        const leadInfoPromises = leads.map(lead_id => fetchLeadInfo(lead_id))
        const leadsInfo = await Promise.all(leadInfoPromises)
        const allNames = leadsInfo.map(info => info.first_name + ' ' + info.last_name)

        if ( pubeurInfo && allNames.length > 0 ) {
          let messageHTML = `
            <h3>Information FormaContacts</h3>

            <h4>Un ou plusieurs contacts vous ont été assignés aujourd'hui :</h4>

            <ul>`

          messageHTML += allNames.map(leadName => `<li>${leadName}</li>`).join('')

          messageHTML += `
            </ul>

            <p><a href="${process.env.REACT_APP_WEBSITE_URL}/leads">Cliquez ici pour vous rendre sur le CRM</a></p>

            <p><i>Ceci est un message automatique depuis le CRM FormaContact, merci de ne pas y répondre.</i></p>
          `

          const messageTEXT = `Un ou plusieurs contacts vous ont été attribué aujourd'hui.\n\nCeci est un message automatique, merci de ne pas y répondre.`
          
          sendMail ({
            identity: pubeurInfo,
            subject: `Nouveaux contacts assignés`,
            messageHTML: messageHTML,
            messageTEXT: messageTEXT,
            sendNow: true
          })

          const listeOfLeads = allNames.map(leadName => `${leadName}`).join(', ')
          
          createNotification ({
            profile_id: pubeurInfo.id,
            type: 'assignation',
            title: `Nouveaux contacts assignés`,
            message: `Votre responsable vous a assigné un ou plusieurs contacts : ${listeOfLeads}. Cliquez pour vous voir tous vos contacts.`,
            url: `/leads`,
          })
        }
      } catch (error) {
        console.error('Error in performAssignation: ', error)
      }
    }

    /*if ( pubeurId ) {
      const fetchInfo = async () => {
        const {data, error} = await supabaseClient
          .from('profiles')
          .select()
          .eq('id', pubeurId)
          .single()

        if (error) {
          console.error('Error fetching pubeur info: ', error)
          return null
        }

        // setPubeurInfo(data)
        return await data
      }

      fetchInfo()

      if ( pubeurInfo ) {
        const messageHTML = `
          <h3>Information FormaContacts</h3>

          <h4>Un ou plusieurs contacts vous ont été assignés aujourd'hui.</h4>

          <p><a href="${process.env.REACT_APP_WEBSITE_URL}/leads">Cliquez ici pour vous rendre sur le CRM</a></p>

          <p><i>Ceci est un message automatique depuis le CRM FormaContact, merci de ne pas y répondre.</i></p>
        `

        const messageTEXT = `Un ou plusieurs contacts vous ont été attribué aujourd'hui.\n\nCeci est un message automatique, merci de ne pas y répondre.`
        
        sendMail ({
          identity: pubeurInfo,
          subject: `Nouveaux contacts assignés`,
          messageHTML: messageHTML,
          messageTEXT: messageTEXT,
          sendNow: true
        })

        createNotification ({
          profile_id: pubeurInfo.id,
          type: 'assignation',
          title: `Nouveaux contacts assignés`,
          message: `Votre responsable vous a assigné un ou plusieurs contacts. Cliquez pour vous voir tous vos contacts.`,
          url: `/leads`,
        })
      }
    }*/
  }

  const assign = (leads, pubeur) => {
    return performAssignation(leads, pubeur)
  }

  const unassign = (leads) => {
    return performAssignation(leads, null)
  } 

  const [selectedLeadIds, setSelectedLeadIds] = useState([])

  const getLeadId = async (inboundContactId) => {
    try {
      const { data, error } = await supabaseClient.from('inbound_contacts')
        .select()
        .eq('id', inboundContactId)
      
      if (error) {
        console.error('Error fetching lead id: ', error)
        return null
      }

      return data[0].lead_id
    } catch (error) {
      console.error('Error fetching lead id: ', error)
      return null
    }
  }

  useEffect(() => {
    const fetchLeadIds = async () => {
      const ids = props.selectedIds
      const promises = []
      
      for (let i = 0; i < ids?.length; i++) {
        const inboundContactId = ids[i]
        promises.push(getLeadId(inboundContactId))
      }
      
      const leadIds = await Promise.all(promises)
      setSelectedLeadIds(leadIds.filter(id => id !== null))
    }

    fetchLeadIds()
  }, [props.selectedIds])

  return (
    <AssignationDialog
      {...props}
      leads={selectedLeadIds}
      assign={assign}
      unassign={unassign}
    />
  )
}