import * as React from "react";
import { useState } from "react";
import { useRedirect, FunctionField, ReferenceField } from "react-admin";
import { Button, Typography, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { CommonDialog } from "../common/Dialog";
import { supabaseClient } from "../supabase";
import { useEffect } from "react";

const LeadUnicityContent = ({ open, contact, handleClose, mode }) => {
  const redirect = useRedirect()
  const [centerLoading, setCenterLoading] = useState(true)
  const [leadCenter, setLeadCenter] = useState(null)
  const [contactPubeurAssocie, setContactPubeurAssocie] = useState(null)
  const [contactPubeurFavourite, setContactPubeurFavourite] = useState('')

  const fetchLeadCenter = async () => {
    try {
      const { data, error } = await supabaseClient
        .from('centers')
        .select('id, name')
        .eq('id', contact?.center_id)

      if (error) {
        console.error('Error fetching lead center: ', error)
        return
      }

      if ( ! data ) return

      setLeadCenter(data[0].name)
    } catch (error) {
      console.error('Error fetching user favourites: ' + error)
    } finally {
      setCenterLoading(false)
    }
  }

  const fetchPubeurAssociation = async () => {
    const { data, error } = await supabaseClient
      .from('profiles')
      .select('id, first_name, last_name')
      .eq('id', contact?.assigned_pubeur)

    if (error) {
      console.error('Error fetching pubeur association: ', error)
    }

    if ( ! data ) return

    setContactPubeurAssocie(data[0]?.first_name + ' ' + data[0]?.last_name)
  }
  
  const fetchPubeurFavourite = async () => {
    const { data, error } = await supabaseClient
      .from('profiles_favourite')
      .select()
      .eq('profile_id', contact?.assigned_pubeur)
      .eq('lead_id', contact?.id)
      .eq('is_fav', true)

    if (error) {
      console.error('Error fetching pubeur favourite: ', error)
    }

    if ( ! data ) return

    setContactPubeurFavourite(data?.length > 0 ? '(Favori)' : '')
  }

  useEffect(() => {
    if ( ! contact ) return

    fetchLeadCenter()
    fetchPubeurAssociation()
    fetchPubeurFavourite()
  }, [contact])

  return (
    <CommonDialog
      open={open}
      handleClose={handleClose}
      titleBgColor='#f99b61'
      title="Doublon de contact"
      size="sm"
    >
      <DialogContent>
        <Typography variant="h6">Contact doublon trouvé :</Typography>
        
        <Typography variant="body1">
          <b>Prénom :</b> {contact?.first_name}<br />
          <b>Nom :</b> {contact?.last_name}<br />
          <b>Centre :</b> {centerLoading ? '' : leadCenter}<br />
          <b>Email :</b> {contact?.email} {contact?.matchingField === 'email' && '(Doublon)'}<br />
          <b>Téléphone :</b> {contact?.phone} {contact?.matchingField === 'phone' && '(Doublon)'}<br />
          <b>Pubeur associé :</b> {contactPubeurAssocie} {contactPubeurFavourite}
        </Typography>
      </DialogContent>
        
      <DialogActions>
        {mode === 'create' ? (
          <>
            <Button onClick={handleClose}>Ne pas récupérer ce contact</Button>
            <Button onClick={() => redirect(`/leads/${contact?.id}`)} autoFocus>
              Compléter ce contact
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => redirect(`/leads/create`)}>Ne pas récupérer ce contact</Button>
            <Button
              autoFocus
              onClick={() => {
                redirect(`/leads/${contact?.id}`);
                handleClose();
              }}
            >
              Compléter ce contact
            </Button>
          </>
        )}
      </DialogActions>
    </CommonDialog>
  )
}

const LeadUnicityAction = React.memo(({ contact, mode, ...props }) => {
  const [open, setOpen] = React.useState(Boolean(contact));

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    setOpen(Boolean(contact));
  }, [contact])

  return (
    <LeadUnicityContent 
      open={open}
      contact={contact}
      mode={mode}
      handleClose={handleClose} 
      {...props} 
    />
  )
})

export default LeadUnicityAction;
