import React, { useEffect, useState } from 'react'
import {
  required,
  useNotify,
  useRecordContext,
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  FormDataConsumer,
  ReferenceInput,
  TextInput,
  useRefresh,
  useGetIdentity,
  useRedirect,
  TimeInput,
  ReferenceArrayInput,
  NumberInput,
} from 'react-admin'
import { Stack, Grid, Typography, InputAdornment, useMediaQuery } from '@mui/material'
import { SimpleForm } from '@react-admin/ra-rbac'

import EventList from './EventList'
import stripos from '../common/stripos'
import { getRole } from '../common/roles'
import { FormCard } from '../Design/Cards'
import { supabaseClient } from '../supabase'
import { RichTextInput } from 'ra-input-rich-text'
import { TextInputCopy } from '../Design/CustomTextInput'
import baseDataProvider from '../supabase/baseDataProvider'
import { toBoolean, toLowerCase } from '../common/Formating'
import { filterBlankSpaces } from '../common/customTextFilter'
import { getItemFromLocalStorage } from '../common/LocalStorage'

import EventIcon from '@mui/icons-material/Event'
import DescriptionIcon from '@mui/icons-material/Description'
import EuroIcon from '@mui/icons-material/Euro'
import ScheduleIcon from '@mui/icons-material/Schedule'
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'

export default {
  list: EventList,
  icon: EventIcon,
  options: {
    label: 'Évènements',
  },
}

const isSchoolType = type => {
  const eventTypes = [
    process.env.REACT_APP_EVENT_TYPE_INTERVENTION,
    process.env.REACT_APP_EVENT_TYPE_FORUM,
    process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE,
    process.env.REACT_APP_EVENT_TYPE_FLY
  ]

  return eventTypes.includes(type)
}

const displayEventSites = type => {
  const eventTypes = [
    process.env.REACT_APP_EVENT_TYPE_JPO,
    process.env.REACT_APP_EVENT_TYPE_JI,
  ]

  return eventTypes.includes(type)
}

const transformData = data => {
  if ( ! data ) return {}

  const {
    name,
    description,
    pubeurs_selection,
    pubeurs_comparison,
    missionDuration,
    missionAmountHor,
    missionDurationAller,
    end_at,
    ...rest
  } = data

  let formattedMissionDuration = missionDuration ? String(missionDuration).replace(',', '.') : null
  let formattedMissionAmountHor = missionAmountHor ? String(missionAmountHor).replace(',', '.') : null
  let formattedMissionDurationAller = missionDurationAller ? String(missionDurationAller).replace(',', '.') : null

  return {
    name: toLowerCase(name),
    description: toLowerCase(description),
    pubeurs_selection: toBoolean(pubeurs_selection),
    pubeurs_comparison: toBoolean(pubeurs_comparison),
    end_at: end_at
      ? ( stripos(end_at, '21:59:59+00') || stripos(end_at, '23:59:59+00') )
        ? end_at
        : end_at +' 21:59:59+00'
      : null,
    missionDuration: formattedMissionDuration ? parseFloat(formattedMissionDuration) : null,
    missionAmountHor: formattedMissionAmountHor ? parseFloat(formattedMissionAmountHor) : null,
    missionDurationAller: formattedMissionDurationAller ? parseFloat(formattedMissionDurationAller) : null,
    ...rest,
  }
}

export const EventInputContent = props => {
  const record = useRecordContext()
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isRespo = identity?.role?.grade >= getRole('responsable')
  const isPubeur = identity?.role?.grade < getRole('responsable')
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'), { noSsr: true })
  const refresh = useRefresh()
  const redirect = useRedirect()
  const notify = useNotify()

  const recordWithBoolean = {
    ...record,
    pubeurs_selection: toBoolean(record?.pubeurs_selection),
    pubeurs_comparison: toBoolean(record?.pubeurs_comparison)
  }

  const [qualificationChoices, setQualificationChoices] = useState([])
  const [siteChoices, setSiteChoices] = useState([])
  const [isEventLeader, setIsEventLeader] = useState(false)
  const [eventPubeurs, setEventPubeurs] = useState([])
  const [smicAmount, setSmicAmount] = useState(null)

  const nowMinusOneDay = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
  const nowPlusTwoWeeks = new Date(new Date().setDate(new Date().getDate() + 12)).toISOString()
  const isFullyVisible = record ? nowMinusOneDay >= record?.date : true
  const rapportStillEditable = record ? nowPlusTwoWeeks >= record?.date : true

  const checkIfIsEventLeader = async () => {
    if ( ! record ) return

    const { data, error } = await supabaseClient
      .from('event_pubeurs')
      .select('is_leader')
      .eq('event_id', record.id)
      .eq('pubeur_id', identity?.id)
      .eq('is_leader', true)

    if ( error ) {
      console.error('Erreur: impossible de vérifier si l\'utilisateur est le leader de l\'évènement', error)
    }

    setIsEventLeader(data?.length > 0)
  }

  const getSitesChoices = async () => {
    if ( ! record ) return

    const { data, error } = await baseDataProvider.getList('sites', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        center_id: record?.center_id
      }
    })

    if ( error ) {
      console.error('Erreur lors de la récupération des sites')
    }

    const siteChoices = data.map(site => ({
      id: site.id,
      name: site.name
    }))

    setSiteChoices(siteChoices)
  }

  const getQualificationChoices = async () => {
    if ( ! record ) return

    const { data, error } = await baseDataProvider.getList('types_qualifications', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        type_id: record?.type_id,
        center_id: record?.center_id ?? null
      }
    })

    if ( error ) {
      console.error('Erreur lors de la récupération des qualifications')
    }

    const qualificationChoices = data.map(qualification => ({
      id: qualification.id,
      name: qualification.name
    }))

    setQualificationChoices(qualificationChoices)
  }

  const fetchEventPubeurs = async () => {
    if ( !isPubeur || !record ) return

    const { data, error } = await supabaseClient
      .from('event_pubeurs')
      .select('is_leader, is_driver, pubeurInfo: profiles(*)')
      .eq('event_id', record.id)

    if (error) {
      console.error('Error fetching event pubeurs:', error.message)
      return
    }
    
    setEventPubeurs(data)
  }

  const getSmicAmount = async () => {
    const { data, error } = await supabaseClient
      .from('current_smic')
      .select('amount')
      .order('created_at', { ascending: false })
      .limit(1)

    if (error) {
      console.error('Error fetching event pubeurs:', error.message)
      return
    }

    setSmicAmount(data[0]?.amount)
  }

  useEffect(() => {
    getQualificationChoices()
    getSitesChoices()
    getSmicAmount()
    checkIfIsEventLeader()
    fetchEventPubeurs()
  }, [])

  const handleSubmit = async (values, form) => {
    if ( record ) {
      values = {
        ...record,
        ...values
      }
    }
    
    values.current_year_id = getItemFromLocalStorage('localCurrentYear')?.id || process.env.REACT_APP_CURRENT_YEAR_ID
    values.missionAmountHor = smicAmount ?? null

    if ( values.schools_ids && ! Array.isArray( values.schools_ids ) ) values.schools_ids = [values.schools_ids] // due to change in the AutocompleteInput component

    const { id, rapport, schools_ids, ...event } = transformData(values)
    const eventValues = { ...event, rapport, schools_ids }

    let newEvent
    if ( id ) {
      await baseDataProvider.update('events', {
        id: id,
        data: eventValues,
        previousData: { record }
      })
    } else {
      newEvent = await baseDataProvider.create('events', {
        data: eventValues
      })
    }

    // a trigger will update schools name in the events on insert/update

    if ( rapport ) {
      const createRapport = async (school_id) => {
        // see if rapport already exists
        const { data, error } = await supabaseClient
          .from('school_reporting')
          .select('*t')
          .eq('event_id', id)
          .eq('school_id', school_id)

        if ( error ) {
          console.error('Erreur lors de la récupération du rapport', error)
          return
        }

        if ( data.length > 0 ) {
          const previousData = data[0]
          // update if exists
          await baseDataProvider.update('school_reporting', {
            id: data[0].id,
            data: {
              rapport,
              updated_at: new Date()
            },
            previousData: { previousData }
          })

          return
        }

        // create if not in table school_reporting
        await baseDataProvider.create('school_reporting', {
          data: {
            event_id: id,
            rapport: rapport,
            school_id: school_id,
          }
        })
      }

      // copy to school_reporting table for each school associated with the event
      schools_ids.forEach(school_id => {
        createRapport(school_id)
      })
    }

    notify(id ? 'Évènement modifié' : 'Évènement créé avec succès', { type: 'success' })
    refresh()
    redirect(newEvent?.data?.id ? `/events/${newEvent?.data?.id}/pubeurs` : null)
    return
  }

  return (
    <SimpleForm
      {...props}
      shouldUnregister
      onSubmit={handleSubmit}
      record={recordWithBoolean}
    >
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          return (
            <>
              { record?.id && isRespo && (
                <TextInputCopy variant="outlined" label="ID de l'évènement" source="id" fullWidth readOnly />
              )}

              <FormCard title="Informations générales">
                { !isPubeur && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ReferenceInput
                        source="type_id"
                        reference="types"
                      >
                        <AutocompleteInput
                          label="Catégorie d'évènement"
                          optionText="name"
                          variant="outlined"
                          filterToQuery={searchText => filterBlankSpaces(searchText)}
                          validate={[required()]}
                          fullWidth
                        />
                      </ReferenceInput>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ReferenceInput
                        source="center_id"
                        reference="centers"
                      >
                        <AutocompleteInput
                          label="Centre"
                          optionText="name"
                          variant="outlined"
                          filterToQuery={searchText => filterBlankSpaces(searchText)}
                          validate={[required()]}
                          defaultValue={isAdmin ? null : identity?.center?.id }
                          readOnly={ ! isAdmin }
                          fullWidth
                        />
                      </ReferenceInput>
                    </Grid>
                  </Grid>
                )}
              
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Nom" source="name" variant="outlined" validate={[required()]} readOnly={isPubeur} autoFocus fullWidth />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <DateInput id="search" label="Début" source="date" variant="outlined" readOnly={isPubeur} validate={[required()]} fullWidth />
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <DateInput id="search" label="Fin" source="end_at" variant="outlined" readOnly={isPubeur} validate={[required()]} fullWidth />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextInput
                      label="Description de l'évènement"
                      source="description"
                      variant="outlined"
                      readOnly={isPubeur}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DescriptionIcon sx={{ fontSize: '1.2rem' }} />
                          </InputAdornment>
                        ),
                      }}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>

                { !isPubeur && formData.type_id && qualificationChoices.length > 0 && (
                  <AutocompleteArrayInput
                    source="qualifications_ids"
                    choices={qualificationChoices}
                    label="Sélectionner une ou plusieurs qualifications"
                    filterToQuery={searchText => ({ 'name@ilike': searchText })}
                    optionText="name"
                    variant="outlined"
                    fullWidth
                  />
                )}

                { !isPubeur && displayEventSites(formData.type_id) && siteChoices.length > 0 &&
                  <AutocompleteArrayInput
                    source="sites_ids"
                    choices={siteChoices}
                    label="Sélectionner un de vos sites"
                    filterToQuery={searchText => ({ 'name@ilike': searchText })}
                    optionText="name"
                    variant="outlined"
                    fullWidth
                  />
                }

                { !isPubeur && formData.center_id && isSchoolType(formData.type_id) &&
                  <ReferenceArrayInput
                    source="schools_ids"
                    reference="schools"
                  >
                    <AutocompleteArrayInput
                      label="Sélectionner un lycée"
                      filterToQuery={searchText => ({ 'name@ilike': searchText })}
                      optionText="name"
                      variant="outlined"

                      fullWidth
                    />
                  </ReferenceArrayInput>
                }

                { !isPubeur && formData.type_id && formData.center_id &&
                  <ReferenceInput
                    source="parent_event"
                    reference="events"
                    filter={{ type_id: formData.type_id, center_id: formData.center_id }}
                  >
                    <AutocompleteInput
                      label="Évènement parent"
                      optionText="name"
                      variant="outlined"
                      filterToQuery={searchText => ({ 'name@ilike': searchText })}
                      readOnly={ ! formData.center_id || formData.center_id.length == 0 ? true : undefined }
                      fullWidth
                    />
                  </ReferenceInput>
                }
              </FormCard>

              { record && record.type_id === process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE &&
                (
                  ( formData.type_id && formData?.schools_ids?.length > 0 ) ||
                  ( record.type_id && record.schools_ids?.length > 0 )
                ) && (
                  <>
                    { ( !isPubeur || ( isPubeur && isFullyVisible ) ) && (
                      <>
                        <Typography variant="h6" color="#327d96" sx={{ mt: 2, mb: 2 }}>Mission lycée</Typography>

                        <Stack spacing={2} mb={2} direction={ isMobile ? 'column' : 'row' } alignItems="top" justifyContent="space-between">
                          <FormCard size="50%">
                            { !isPubeur && (
                              <>
                                <DateInput id="search" label="Début de l'affichage de l'annonce" source="missionStartHidden" variant="outlined" fullWidth />
                                <DateInput id="search" label="Début de l'affichage avec informations" source="missionStartAff" variant="outlined" fullWidth />
                              </>
                            )}

                            <TimeInput
                              id="search"
                              label="Heure de départ du point de RDV"
                              source="timeStart"
                              variant="outlined"
                              readOnly={isPubeur}
                              fullWidth
                            />

                            <TimeInput
                              id="search"
                              label="Heure de départ du lycée"
                              source="timeEnd"
                              variant="outlined"
                              readOnly={isPubeur}
                              fullWidth
                            />
                          </FormCard>

                          <FormCard size="50%">
                            { ! isPubeur && (
                              <TextInput
                                label="Nombre d'heures sur place"
                                source="missionDuration"
                                variant="outlined"
                                readOnly={isPubeur}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ScheduleIcon sx={{ fontSize: '1.2rem' }} />
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                              />
                            )}

                            <TextInput
                              label="Rémunération horaire"
                              source="missionAmountHor"
                              variant="outlined"
                              readOnly={isPubeur}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EuroIcon sx={{ fontSize: '1.2rem' }} />
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />

                            <TextInput
                              label="Nombre d'heures pour l'aller"
                              source="missionDurationAller"
                              variant="outlined"
                              readOnly={isPubeur}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <DepartureBoardIcon sx={{ fontSize: '1.2rem' }} />
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />

                            <NumberInput
                              label="Total de KM parcourus (A/R)"
                              source="totalKm"
                              variant="outlined"
                              readOnly={isPubeur}
                              fullWidth
                            />
                          </FormCard>
                        </Stack>
                      </>
                    )}

                    { isPubeur && (
                      <FormCard title="Votre groupe">
                        <ul>
                          { eventPubeurs.length > 0 ? eventPubeurs.map(pubeur => (
                            <li key={pubeur.pubeurInfo.id}>
                              <Typography variant="body1" color="textPrimary">
                                {pubeur.pubeurInfo.first_name} {pubeur.pubeurInfo.last_name}
                                { pubeur.is_leader && <span> (leader)</span> }
                                { pubeur.is_driver && <span> (conducteur)</span> }
                              </Typography>
                            </li>
                          )) : <p>Aucun pubeur n'a encore été assigné à cet évènement.</p> }
                        </ul>
                      </FormCard>
                    )}

                    {( !isPubeur ||
                     ( isPubeur && isEventLeader && isFullyVisible && rapportStillEditable ) ) && (
                      <FormCard title="Rapport de mission">
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 0, mb: 4 }}>Celui-ci sera consultable dans la fiche lycée</Typography>

                        <RichTextInput
                          label={false}
                          source="rapport"
                          id="description_mission_lycee"
                          sx={{ '& .RaRichTextInput-editorContent #description_mission_lycee': { minHeight: '300px !important' } }}
                          fullWidth
                        />
                      </FormCard>
                    )}
                  </>
                )
              }
            </>
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  )
}
