import React, { useEffect, useState } from 'react'
import { Link, Button, useRedirect } from 'react-admin'
import { Grid, Box, Chip, Card, CardMedia, CardContent, Typography } from '@mui/material'

import { supabaseClient } from '../../supabase'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const DashboardPubeurEvents = ({ isMobile }) => {
  const redirect = useRedirect()

  const [isLoading, setIsLoading] = useState(true)
  const [nextEventsPubeur, setNextEventsPubeur] = useState([])

  useEffect(() => {
    const fetchEventsTypesNames = async (events) => {
      const eventsTypesIds = events.map(event => event.type_id)

      const { data, error } = await supabaseClient
        .from('types')
        .select('id, name')
        .in('id', eventsTypesIds)

      if (error) {
        console.error('Error fetching events types names:', error)
        return
      }

      const eventsTypesNames = data.reduce((acc, type) => {
        acc[type.id] = type.name
        return acc
      }, {})

      setNextEventsPubeur(events.map(event => ({
        ...event,
        type_name: eventsTypesNames[event.type_id],
      })))
    }

    const fetchNextEvents = async () => {
      const today = new Date().toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format
    
      const { data, error } = await supabaseClient
        .from('events_view')
        .select('*')
        .gte('date', today)
        // .or(`date.gte.${today},missionStartAff.gte.${today},missionStartHidden.gte.${today}`)
        .order('date', { ascending: false })
        .limit(5)
    
      if (error) {
        console.error('Error fetching next events:', error)
        return
      }
    
      setNextEventsPubeur(data)
      fetchEventsTypesNames(data)
      setIsLoading(false)
    }    

    fetchNextEvents()
  }, [])

  return (
    <Card sx={{ width: isMobile ? '100%' : '33%', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px' }}>
      { !isMobile && (
        <CardMedia
          component="img"
          alt="Évènements à venir"
          height="140"
          image="https://images.unsplash.com/photo-1484373030460-8de45ac8796d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          sx={{ opacity: .75 }}
        />
      )}

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" mb={2}>
          Évènements à venir
        </Typography>

        { isLoading ? 'Chargement...'
          : nextEventsPubeur?.length > 0
            ? nextEventsPubeur?.map(event => {
              let eventDate = new Date(event.date)
              const options = { year: 'numeric', month: 'long', day: 'numeric', TimeRanges: 'Europe/Paris' }
              eventDate = eventDate.toLocaleDateString('fr-FR', options)
              const timeStart = event.timeStart ? 'à '+ new Date(event.timeStart).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) : null
              const fullDateStart = `Le ${eventDate} ${timeStart ?? ''}`

              const now = new Date().toISOString()
              const nowMinusOneDay = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
              const isNotHidden = now >= event.missionStartHidden
              const isVisible = now >= event.missionStartAff
              const isFullyVisible = nowMinusOneDay >= event.date
            
              return (
                <Box
                  key={event.id}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: 3,
                    mt: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Chip label={event.type_name} sx={{ marginBottom: 1, borderRadius: '5px', backgroundColor: event.typeColor }} />
                    </Grid>

                    <Grid item xs={6} textAlign="right">
                      <Typography variant="caption" color="text.secondary">
                        <b>{fullDateStart}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  
                  { isFullyVisible && (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        <Link to={`events/${event.id}`}>{event.name}</Link>
                      </Typography>

                      {event?.schools_names?.map((schoolName, index) => (
                        <Chip
                          key={index + '_' + schoolName}
                          label={schoolName}
                          variant="contained"
                          sx={{ mt: 2, p: 1, backgroundColor: '#c5dedd', color: '#222', '&:hover': { backgroundColor: '#c5dedd' } }}
                          icon={<OpenInNewIcon />}
                          onClick={(e) => {
                            e.stopPropagation()
                            redirect(`/schools/${event?.schools_ids[index]}`)  // Assuming schools_ids is also present
                          }}
                        />
                      ))}
                    </>
                  )}

                  { isVisible && !isFullyVisible && (
                    <Typography variant="body2" color="text.secondary">
                      <Link to={`events/${event.id}`}>{event.name}</Link>
                    </Typography>
                  )}

                  { isNotHidden && !isVisible && !isFullyVisible && (
                    <p>Cet événement n'est pas encore disponible.</p>
                  )}
                </Box>
              )
            }) : (
              <Typography variant="body2" color="text.secondary">
                Aucun événement à venir
              </Typography>
            )
        }
      </CardContent>
    </Card>
  )
}

export default React.memo(DashboardPubeurEvents)