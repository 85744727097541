import { useState } from 'react'
import { useGetIdentity, useRedirect, Button } from 'react-admin'
import { Typography } from '@mui/material'

import './dialogImport.css'

import CustomDialog from '../common/Dialog'
import { supabaseClient } from '../supabase'
import { getClassIdByName } from '../common/classes'
import addNewComment from '../common/addLeadComment'
import { ImportButton } from 'react-admin-import-csv'
import { getSpecialitiesIdByName } from '../common/specialities'
import { insertIntoTable, checkForDuplicates, CheckIfEventExistsInContact } from './LeadUtils'
import { getValidEmail, formatPhoneNumber, toBoolean, toCamelCase, toCapitalCase } from '../common/Formating'

import TaskAltIcon from '@mui/icons-material/TaskAlt'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PublishIcon from '@mui/icons-material/Publish'
import { getItemFromLocalStorage } from '../common/LocalStorage'

const localCenterId = getItemFromLocalStorage('localCenter')?.id

const handleEvents = async (leadId, eventId, qualificationInfo, leadAutoComment) => {
  console.log('Handling event...');
  
  // set data for insert/update
  const eventInfo = {}
  eventInfo.lead_id = leadId
  eventInfo.event_id = eventId
  eventInfo.current_year_id = process.env.REACT_APP_CURRENT_YEAR_ID
  eventInfo.qualifications = qualificationInfo
    ? { [qualificationInfo.name]: qualificationInfo }
    : null
  
  try {
    // Check if the event is already associated with the lead
    const isEventInContact = await CheckIfEventExistsInContact(leadId, eventInfo.event_id)
    
    if ( ! isEventInContact.res ) {
      // Insert event if it does not exist
      console.log('Nouvel événement dans le parcours:', eventInfo)

      await insertIntoTable('lead_events', eventInfo)

      leadAutoComment += 'Événement ajouté. '
      if (qualificationInfo) leadAutoComment += 'Qualification ajoutée. '
    } else {
      // Log and update existing event if qualifications are present
      console.log('Événement déjà enregistré sur le contact.')
      leadAutoComment += 'Événement déjà enregistré sur le contact. '

      if (qualificationInfo) {
        const updatedQualifications = isEventInContact.event.qualifications
          ? { ...isEventInContact.event.qualifications, [qualificationInfo.name]: qualificationInfo }
          : { [qualificationInfo.name]: qualificationInfo }

        console.log('Qualification à ajouter...', updatedQualifications)

        const { error: errorUpdateCurrentEvent } = await supabaseClient
          .from('lead_events')
          .update({ qualifications: updatedQualifications })
          .eq('lead_id', leadId)
          .eq('event_id', eventInfo.event_id)
          .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)
        
        if ( errorUpdateCurrentEvent ) {
          console.error('Error updating lead_events for new qualifications...', errorUpdateCurrentEvent)
        } else {
          leadAutoComment += 'Qualification ajoutée. '
        }

        return leadAutoComment
      }
    }
  } catch (error) {
    console.error('Error checking if event exists in contact: ', error)
  }
}

const getQualificationInfo = async (qualificationId) => {
  const { data, error } = await supabaseClient
    .from('types_qualifications')
    .select('*')
    .eq('id', qualificationId)
  
  if (error) {
    console.error('Error fetching qualification info', error)
    return
  }

  return {
    id: data[0]?.id,
    name: data[0]?.name
  }
}

const ConfirmImportComplete = ({ open, onClose, newLeads, totalLeads }) => {
  const newleads = newLeads - 1
  const totalleads = totalLeads - 1
  
  return (
    <CustomDialog
      open={open}
      icon={<PublishIcon />}
      handleClose={onClose}
      title={<Typography variant="h5" color="green"><CheckCircleIcon sx={{ fontSize: '1.25rem' }} /> Import réalisé avec succès</Typography>}
      fullWidth
      maxWidth="sm"
    >
      <div style={{ padding: '20px 40px' }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
          {newleads > 1 ? `${newleads} nouveaux contacts ajoutés.` : `${newleads} nouveau contact ajouté.` }
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
          {totalleads > 1 ? `${totalleads} contacts importés au total.` : `${totalleads} nouveau contact importé au total.` }
        </Typography>

        <Typography variant="body1">
          Vous pouvez à présent fermer cette fenêtre.
        </Typography>

        <Button
          label="Fermer"
          variant="contained"
          startIcon={<TaskAltIcon />}
          onClick={onClose}
          style={{ margin: '20px auto' }}
        />
      </div>
    </CustomDialog>
  )
}

const updateLead = async (leadId, data) => {
  const { data: lead, error } = await supabaseClient
    .from('leads')
    .update(data)
    .eq('id', leadId)
    .select()
  
  if (error) {
    console.error('Error updating lead: ', error)
    return
  }
  
  return lead
}

const isUUID = (value) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(value)
}

const isNonEmptyString = (value) => 
  (typeof value === 'string' || typeof value === 'number') && String(value).trim().length > 0

const transformLead = (row) => ({
  last_name: isNonEmptyString(row['Nom']) ? toCapitalCase(row['Nom'].trim()) : null,
  first_name: isNonEmptyString(row['Prénom']) ? toCamelCase(row['Prénom'].trim()) : null,
  email: isNonEmptyString(row['Email']) ? getValidEmail(row['Email']) : null,
  phone: isNonEmptyString(row['Téléphone']) ? formatPhoneNumber(row['Téléphone']) : null,
  class_id: isNonEmptyString(row['Classe'])
    ? getClassIdByName(row['Classe'], row['ID du centre'] ?? localCenterId)
    : null,
  custom_class: isNonEmptyString(row['Classe personnalisée']) ? row['Classe personnalisée'] : null,
  school_id: isNonEmptyString(row['ID Lycée']) ? row['ID Lycée'] : null,
  options: isNonEmptyString(row['Options']) ? row['Options'].split(',').map(option => getSpecialitiesIdByName(option)) : [],
  comment: isNonEmptyString(row['Commentaire']) ? row['Commentaire'].trim() : null,
  assigned_pubeur: isUUID(row['ID du pubeur assigné']) ? row['ID du pubeur assigné'] : null,
  address: {
    street: isNonEmptyString(row['Rue']) ? row['Rue'].trim() : null,
    postalCode: isNonEmptyString(row['Code postal']) ? row['Code postal'] : null,
    city: isNonEmptyString(row['Ville']) ? row['Ville'].trim() : null,
  },
  interested: toBoolean(row['Intéressé']) ?? false,
  not_interested: toBoolean(row['Pas intéressé']) ?? false,
  stop_rgpd: toBoolean(row['Stop RGPD']) ?? false,
  current_year_id: isUUID(row['ID de l\'année en cours']) ? row['ID de l\'année en cours'] : process.env.REACT_APP_CURRENT_YEAR_ID,
  lead_source_id: isUUID(row['Provenance du lead']) ? row['Provenance du lead'] : process.env.REACT_APP_LEAD_SOURCE_CSV_IMPORT,
  center_id: isUUID(row['ID du centre']) ? row['ID du centre'] : null,
  status_inscription_id: isUUID(row['ID du statut d\'inscription']) ? row['ID du statut d\'inscription'] : null,
})

const transformLeadEvent = (row) => {
  const leadEvents = {
    event_id: row['ID Événement(s)'] ?? process.env.REACT_APP_EVENT_CSV_IMPORT,
    qualifications: row['ID de la qualification événement'] ?? null,
    pubeur_id: row['ID du pubeur assigné'] ?? null,
    comment: row['Commentaire'] ?? null,
  }

  if (
    row['ID Événement Hippocast APPLI'] ||
    row['ID Événement Hippocast Webinaire'] ||
    row['ID Événement Hippocast RDV']
  ) {
    leadEvents.hippocast = {
      'appli': row['ID Événement Hippocast APPLI'] ?? null,
      'webinaire': row['ID Événement Hippocast Webinaire'] ?? null,
      'rdv': row['ID Événement Hippocast RDV'] ?? null,
    }
  }

  return leadEvents
}

const createLeadObject = (row) => ({
  lead: transformLead(row),
  lead_event: transformLeadEvent(row),
})

export const transformRows = async (csvRows) => {
  const results = csvRows.map(async row => {
    const allEntriesNull = Object.values(row).every(entry => entry === null)

    if ( allEntriesNull ) {
      return { lead: {}, lead_event: {} }
    }
    
    const res = createLeadObject(row) // creating object with lead, lead_event and lead_parents
    if ( ! res ) return { lead: {}, lead_event: {} }

    res.lead.consider = true // adding a flag to consider the lead if row not empty of broken

    return res.lead.consider
      ? res
      : { lead: {}, lead_event: {} }
  })

  return await Promise.all(results)
}

export const ImportLeadButton = props => {
  const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const localCenter = identity?.center
  const [dialogOpen, setDialogOpen] = useState(false)
  const [newLeads, setNewLeads] = useState(0)
  const [totalLeads, setTotalLeads] = useState(0)

  const handleDialogClose = () => {
    setDialogOpen(false)
    redirect('/')
  }

  const config = {
    logging: true,
    disableImportNew: true,
    disableCreateMany: true,
    transformRows: transformRows,
    preCommitCallback: async (action, values) => {
      const errors = []

      let total = 1
      let nouveau = 1
      for (const row of values) {
        if ( ! row ) continue
        if ( ! row.lead.center_id ) row.lead.center_id = localCenter?.id
        if ( ! row.lead.consider ) continue
        
        total++

        /* get qualification info if present */
        let qualificationInfo
        if ( row.lead_event.qualifications ) {
          qualificationInfo = await getQualificationInfo(row.lead_event.qualifications)
        }

        /* Check for duplicates, then possible updates, before inserting new lead */
        if ( row.lead.phone !== '' || row.lead.email !== '' ) {
          try {
            const existingLead = await checkForDuplicates(null, row.lead.phone ? formatPhoneNumber(row.lead.phone) : null, row.lead.email ? getValidEmail(row.lead.email) : null, row.lead.center_id ?? localCenter?.id)
            
            if ( typeof existingLead !== 'undefined' && typeof row !== 'undefined' ) {
              let leadAutoComment = '*AUTO* Ajout depuis import CSV. Doublon. '

              console.log('Doublon trouvé : ', existingLead.first_name +' '+ existingLead.last_name)
    
              /* check if event already in doublon */
              if ( ! row.lead_event?.hippocast ) {
                console.log('Handling event for duplicate lead...');

                leadAutoComment = await handleEvents(
                  existingLead.id,
                  row.lead_event.event_id ?? process.env.REACT_APP_EVENT_CSV_IMPORT,
                  qualificationInfo,
                  leadAutoComment
                )
              } else {
                /* special for Hippocast : extra events */
                if ( row.lead_event?.hippocast?.appli ) {
                  console.log('Hippocast appli...')
                  
                  const hippocastComment = await handleEvents(existingLead.id, row.lead_event.hippocast.appli, null, '')
                  await addNewComment(existingLead.id, identity?.id, row.lead_event.event_id ?? null, hippocastComment)
                }

                if ( row.lead_event?.hippocast?.webinaire ) {
                  console.log('Hippocast webinaire...')

                  const hippocastComment = await handleEvents(existingLead.id, row.lead_event.hippocast.webinaire, null, '')
                  await addNewComment(existingLead.id, identity?.id, row.lead_event.event_id ?? null, hippocastComment)
                }

                if ( row.lead_event?.hippocast?.rdv ) {
                  console.log('Hippocast RDV...')

                  const hippocastComment = await handleEvents(existingLead.id, row.lead_event.hippocast.rdv, null, '')
                  await addNewComment(existingLead.id, identity?.id, row.lead_event.event_id ?? null, hippocastComment)
                }
              }
              
              const leadId = existingLead.id
              const duplicateLead = { ...row.lead }
    
              if ( duplicateLead.assigned_pubeur !== null && duplicateLead.assigned_pubeur !== '' ) {
                console.log('Nouveau pubeur assigné: ', duplicateLead.assigned_pubeur)
                leadAutoComment += 'Nouveau pubeur assigné. '
              }

              // delete empty rows so that we don't update with empty values
              Object.keys(duplicateLead).forEach(key => {
                if ( ! duplicateLead[key] ) delete duplicateLead[key]
              })

              // not in leads table so we remove to avoid errors
              delete duplicateLead.id
              delete duplicateLead.consider
              delete duplicateLead.duplicate_source
              delete duplicateLead.fts
              delete duplicateLead.updated_at
              delete duplicateLead.consider
              delete duplicateLead.options
              delete duplicateLead.address
              delete duplicateLead.lead_source_id // do not update the source

              console.log('Mise à jour des informations du lead...')
              await updateLead ( leadId, duplicateLead )
              
              await addNewComment(leadId, identity?.id, row.lead_event.event_id ?? null, leadAutoComment)

              // log previous data in a comment
              await addNewComment(leadId, identity?.id, row.lead_event.event_id ?? null,
                `*AUTO* Contact mis à jour depuis l'import.
                Données d'origine :
                [Prénom : ${existingLead.first_name}],
                [Nom : ${existingLead.last_name}],
                [Téléphone : ${existingLead.phone}],
                [Email : ${existingLead.email}],
                [Adresse : ${existingLead.address?.street}, ${existingLead.address?.postalCode} ${existingLead.address?.city}],
                [Classe : ${existingLead.class_id}].`
              )

              if ( row.lead.comment ) {
                await addNewComment(leadId, identity?.id, row.lead_event.event_id ?? null, row.lead.comment)
              }
    
              row.lead.consider = false
            }  // if not duplicate, res.lead_event will be used to add event to contact at the end 
          } catch (error) {
            console.error('Error checking for duplicates: ', error)
          }
        }

        /* Inserting new leads */
        if ( row.lead.consider ) {
          console.log('Nouveau contact: ', row.lead.first_name +' '+ row.lead.last_name)
          let leadAutoComment = '*AUTO* Ajout depuis import CSV. '

          delete row.lead.consider

          const { result: newLead, error: leadError } = await insertIntoTable('leads', row.lead)

          await Promise.all([newLead])

          if (leadError) {
            errors.push({ error: leadError, rowData: row.lead })
            return
          }
          
          nouveau++

          /* then add event to leads' parcours */
          if (row.lead_event.event_id) {
            if ( ! row.lead_event?.hippocast ) {
              console.log('Handling event for regular new lead...');
              
              leadAutoComment = await handleEvents(
                newLead.id,
                row.lead_event.event_id,
                qualificationInfo,
                leadAutoComment
              )
            }

            // row.lead_event.lead_id = newLead.id
            // row.lead_event.current_year_id = process.env.REACT_APP_CURRENT_YEAR_ID

            // // add qualification to event if present
            // row.lead_event.qualifications = qualificationInfo ? { [qualificationInfo.name]: qualificationInfo } : null
            
            // try {
            //   const isEventInContact = await CheckIfEventExistsInContact ( newLead.id, row.lead_event.event_id )

            //   if ( ! isEventInContact.res ) {
            //     await insertIntoTable('lead_events', row.lead_event)
            //     leadAutoComment += 'Événement ajouté. '
            //   } else {
            //     console.log('Événement déjà enregistré sur le contact.')
            //     leadAutoComment += 'Événement déjà enregistré sur le contact. '

            //     // update qualification in the event
            //     if ( qualificationInfo ) {
            //       const updatedQualifications = isEventInContact.event.qualifications
            //         ? { ...isEventInContact.event.qualifications, [qualificationInfo.name]: qualificationInfo }
            //         : { [qualificationInfo.name]: qualificationInfo }
                  
            //       const updateCurrentEvent = await supabaseClient
            //         .from('lead_events')
            //         .update({ qualifications: updatedQualifications })
            //         .eq('lead_id', row.lead_event.lead_id)
            //         .eq('event_id', row.lead_event.event_id)
            //         .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)
                  
            //       await Promise.all([updateCurrentEvent])

            //       leadAutoComment += 'Qualification ajoutée. '
            //     }
            //   }
            // } catch (error) {
            //   console.error('Error checking if event exists in contact: ', error)
            // }
          }

          await addNewComment(newLead.id, identity?.id, row.lead_event.event_id ?? null, leadAutoComment)

          /* special for Hippocast : extra events */
          if ( row.lead_event?.hippocast?.appli ) {
            console.log('Hippocast appli...')
            
            const hippocastComment = await handleEvents(newLead.id, row.lead_event.hippocast.appli, null, '')
            await addNewComment(newLead.id, identity?.id, row.lead_event.event_id ?? null, hippocastComment)
          }

          if ( row.lead_event?.hippocast?.webinaire ) {
            console.log('Hippocast webinaire...')

            const hippocastComment = await handleEvents(newLead.id, row.lead_event.hippocast.webinaire, null, '')
            await addNewComment(newLead.id, identity?.id, row.lead_event.event_id ?? null, hippocastComment)
          }

          if ( row.lead_event?.hippocast?.rdv ) {
            console.log('Hippocast RDV...')

            const hippocastComment = await handleEvents(newLead.id, row.lead_event.hippocast.rdv, null, '')
            await addNewComment(newLead.id, identity?.id, row.lead_event.event_id ?? null, hippocastComment)
          }

          if ( row.lead.comment ) {
            await addNewComment(newLead.id, identity?.id, row.lead_event.event_id ?? null, row.lead.comment)
          }
        }

        console.log('_______________')
      }

      console.log('Imported', nouveau, 'new leads out of', total, 'total leads.');

      setDialogOpen(true)
      setNewLeads(nmbr => nmbr + nouveau )
      setTotalLeads(nmbr => nmbr + total)

      if (errors.length > 0) {
        console.error('Errors occurred during import:', errors)
      }

      return []
    },
    parseConfig: {
      delimiter: '',
      dynamicTyping: true,
      skipEmptyLines: true,
      encoding: 'UTF-8'
    },
  }

  return <>
    <ImportButton {...props} {...config} />

    <ConfirmImportComplete open={dialogOpen} onClose={handleDialogClose} newLeads={newLeads} totalLeads={totalLeads} />
  </>
}