import { supabaseClient } from '../supabase/'
import { assignDatesToAllMessages, correctYearMessagesDates, findSimilarMessages, handleSimilarMessage,  } from './messagesProcessing'

const submitImport = async (
  setLoadingDisplay, setOpen,
  identity, refresh, notify,
  convSystem, uploadState, conversationInfo,
  setIsLoading,
  refreshConversations,
  setPubeurInfo, pubeurInfo,
  setLeadInfo, leadInfo, isPDFiPhone
) => {
  if (!leadInfo.id) {
    uploadState = {
      isMulti: false,
      lastFile: false,
      messages: [],
    }
  
    conversationInfo = null
    setPubeurInfo({ id: null, name: null })
    setLeadInfo({ id: null, name: null })
    setIsLoading(false)

    return
  }

  // if only one message, it has to be a sender
  if ( uploadState.messages.length === 1 ) {
    uploadState.messages[0].isSenderMessage = true
  }

  const conversationMessages = mergeMessagesWithUpdatedOrder(uploadState.messages)
  let finalConversation = conversationMessages
  if ( convSystem !== 'android' ) {
    let cleanedConversationMessages = conversationMessages
    if ( !isPDFiPhone ) {
      console.log('Finding similar messages...', conversationMessages)
      cleanedConversationMessages = findSimilarMessages(conversationMessages)
      console.log('cleanedConversationMessages', cleanedConversationMessages)
    }

    const cleanedYearDatesMessage = correctYearMessagesDates(cleanedConversationMessages)
    // console.log('cleanedYearDatesMessage', cleanedYearDatesMessage)
    const conversationWithDates = assignDatesToAllMessages(cleanedYearDatesMessage)
    // console.log('conversationWithDates', conversationWithDates)
    finalConversation = conversationWithDates
  }

  setLoadingDisplay(
    (prevState) => ({
      ...prevState,
      message: 'Importation... Status de la conversation...'
    })
  )

  if ( !conversationInfo ) {
    conversationInfo = await getOrCreateConversation(convSystem, leadInfo, pubeurInfo)
  }

  if (!conversationInfo || !conversationInfo.id) return

  setLoadingDisplay(
    (prevState) => ({
      ...prevState,
      message: 'Importation... Ajouts des messages...'
    })
  )

  // Go through each message in the batch
  let processedMessages = [...finalConversation] // Copy the messages to avoid mutation
  for (let i = 0; i < processedMessages.length; i++) {
    const message = processedMessages[i]
    const { isSenderMessage, isDate, isResponse, text: newMessageText, createdAt: newMessageCreatedAt } = message
    const senderType = isSenderMessage ? 'pubeur' : 'lead'
    const senderId = isSenderMessage ? identity?.id : leadInfo.id

    // Identify similar messages in the existing conversation
    const similarMessage = await (async () => {
      for (let existingMessage of conversationInfo.existingMessages) {
        const isSimilar = handleSimilarMessage(existingMessage.text, newMessageText)

        if (isSimilar) {
          return existingMessage // Return the first similar message found
        }
      }

      return null // No similar message found
    })()

    // If there's a similar message, update it or skip if the new one is shorter
    if (similarMessage) {
      if (newMessageText.length > similarMessage.text.length) {
        console.log(`Updating message "${similarMessage.text}" with longer one "${newMessageText}"`)
        await updateMessage(similarMessage.id, newMessageText, newMessageCreatedAt)
      } else {
        console.log(`Skipping shorter or equal message "${newMessageText}"`)
      }

      continue // Skip to the next message
    }

    // Otherwise, insert the new message
    const messageValues = {
      sender_id: senderId,
      sender_type: senderType,
      text: newMessageText,
      paid: !isDate && !isResponse && newMessageText.length > 20,
      isDate,
      isResponse,
      created_at: newMessageCreatedAt,
    }

    await insertNewMessage(messageValues, conversationInfo.id)
  }

  setLoadingDisplay(
    (prevState) => ({
      ...prevState,
      message: 'Importation... Finalisation...'
    })
  )

  const { error } = await supabaseClient
    .rpc('rpc_update_paie_pubeur_after_bulk', { 'sender': identity?.id })

  if ( error ) {
    console.error('Error updating paie pubeur with rpc', error)
  }

  // Check if this is the last file and last message in the multi-upload process
  if (!uploadState.active || uploadState.lastFile) {
    notify('Conversation importée avec succès', { type: 'success' })

    uploadState = {
      isMulti: false,
      lastFile: false,
      messages: [],
    }
    conversationInfo = null
    setLoadingDisplay({ fileName: null, message: null })
    setPubeurInfo({ id: null, name: null })
    setLeadInfo({ id: null, name: null })
    setIsLoading(false)
    refresh()
    setOpen(false)

    if ( refreshConversations ) {
      // function to refresh the conversations list
      refreshConversations()
    }

    return {
      uploadState,
      conversationInfo
    }
  }
}

const getOrCreateConversation = async (convSystem, leadInfo, pubeurInfo) => {
  console.log('Checking if conversation already exists...')

  // Check if conversation already exists
  const { data, error } = await supabaseClient
    .from('conversations')
    .select('id')
    .eq('lead_id', leadInfo?.id)
    .eq('pubeur_id', pubeurInfo?.id)

  if (error) {
    console.error(error)
    return null
  }

  const convId = data[0]?.id

  if (data.length > 0) {
    // Fetch existing messages for comparison
    const { data: existingMessages, error: messagesError } = await supabaseClient
      .from('conversation_messages')
      .select('id, text')
      .eq('conversation_id', convId)
      .order('created_at', { ascending: true })

    if (messagesError) {
      console.error(messagesError)
      return null
    }

    // Remove messages with "none dated" date
    const messageTodayDatesRegex = /(Aujourd(’|')hui|Today)\s\d{1,2}\s?:\s?\d{2}/iu
    const messageYesterdayDatesRegex = /(Yesterday|Hier|Avant\s?-?\s?hier)\s(?:à\s)?\d{1,2}\s?:\s?\d{2}(?:[ap]m)?/iu

    const messagesToDelete = existingMessages.filter(message =>
      message?.text && (
        messageTodayDatesRegex.test(message?.text) ||
        messageYesterdayDatesRegex.test(message?.text)
      )
    )

    if (messagesToDelete?.length > 0) {
      const idsToDelete = messagesToDelete.map(msg => msg.id)

      const { error: deleteError } = await supabaseClient
        .from('conversation_messages')
        .delete()
        .in('id', idsToDelete)

      if (deleteError) {
        console.error('Error deleting messages:', deleteError)
        return null
      }

      console.log(`Deleted ${messagesToDelete.length} message(s) matching the regex`)
    }

    return {
      id: convId,
      existingMessages: existingMessages.filter(msg => 
        !messagesToDelete.find(deletedMsg => deletedMsg.id === msg.id)
      ),
    }
  }

  // Create a new conversation if none exists
  console.log('Creating a new conversation...')

  const { data: newConversation, error: newConversationError } = await supabaseClient
    .from('conversations')
    .insert({
      lead_id: leadInfo.id,
      pubeur_id: pubeurInfo.id,
      current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID,
      system: convSystem ?? 'iPhone'
    })
    .select()

  if (newConversationError) {
    console.error(newConversationError)
    return null
  }

  return {
    id: newConversation[0].id,
    existingMessages: [],
  }
}

const mergeMessagesWithUpdatedOrder = (messages) => {
  return messages.reduce((acc, cur) => [...acc, ...cur], [])
  .map((message, index) => ({ ...message, order: index }))
}

const insertNewMessage = async (messageValues, conversationId) => {
  if ( ! conversationId ) return

  const { error } = await supabaseClient
    .from('conversation_messages')
    .insert({ ...messageValues, conversation_id: conversationId })

  if (error) {
    console.error('Error inserting message:', error)
  }
}

const updateMessage = async (messageId, newText, newCreatedAt) => {
  const { error } = await supabaseClient
    .from('conversation_messages')
    .update({ text: newText, created_at: newCreatedAt })
    .eq('id', messageId)

  if (error) {
    console.error('Error updating message:', error)
  }
}

export default submitImport