import { useState } from 'react'
import {
  required,
  useCreateContext,
  useEditContext,
  useNotify,
  useRecordContext,
  useRefresh,
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
  useGetIdentity,
  useRedirect,
  BooleanInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { SimpleForm } from '@react-admin/ra-rbac'
import { RichTextInput } from 'ra-input-rich-text'

import TasksList from './TasksList'
import TasksTypes from './TasksTypes'
import { toLowerCase } from '../common/Formating'
import { TextInputCopy } from '../Design/CustomTextInput'
import { filterBlankSpaces } from '../common/customTextFilter'

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import baseDataProvider from '../supabase/baseDataProvider'
import { supabaseClient } from '../supabase'

export default {
  list: TasksList,
  icon: AssignmentTurnedInIcon,
  options: { label: 'Tâches' },
}

const transformData = data => {
  const { name, pubeurs_selection, pubeurs_comparison, ...rest } = data

  return {
    name: toLowerCase(name),
    ...rest,
  }
}

export const TasksInputContent = props => {
  const record = useRecordContext()
  const notify = useNotify()
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const editContext = useEditContext()
  const createContext = useCreateContext()

  const role = identity?.role?.reference

  const [chosenCenter, setChosenCenter] = useState(identity?.center?.id)

  const handleSubmit = async (values) => {
    values.year_id = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID
    values.center_id = chosenCenter || identity?.center?.id
    const { id, ...tasks } = transformData(values)

    let newTask
    if ( id ) {
      // activer/désactiver une tâche
      if ( values.active !== record.active ) {
        if ( ! values.active ) {
          console.log('tâche désactivée')

          // remove task from all leads
          const { error } = await supabaseClient
            .from('lead_tasks')
            .update({ active: false })
            .eq('task_id', record.id)

          if ( error ) {
            notify('Ooops! La tâche n\'a pas pu être désactivée sur les contacts concernés...', { type: 'error' })
            console.error('Ooops, impossible to remove the task from leads')
          }
        }

        if ( values.active ) {
          console.log('tâche réactivée')

          // remove task from all leads
          const { error } = await supabaseClient
            .from('lead_tasks')
            .update({ active: true })
            .eq('task_id', record.id)

          if ( error ) {
            notify('Ooops! La tâche n\'a pas pu être réactivée sur les contacts concernés...', { type: 'error' })
            console.error('Ooops, impossible to remove the task from leads')
          }
        }
      }

      // update task
      await baseDataProvider.update('tasks', {
        id: id,
        data: tasks,
        previousData: { record }
      })
    } else {
      newTask = await baseDataProvider.create('tasks', {
        data: tasks
      })
    }

    notify(id ? 'Tâche modifiée' : 'Tâche créée avec succès', { type: 'success' })
    refresh()
    redirect(newTask?.data?.id ? `/tasks/${newTask?.data?.id}/actions` : null)
    return
  }

  return (
    <SimpleForm
      {...props}
      shouldUnregister
      onSubmit={handleSubmit}
    >
      { record?.id && (
        <>
          <BooleanInput label="Tâche active et visible par les pubeurs" source="active" />
          <TextInputCopy label="ID" source="id" variant="outlined" fullWidth readOnly />
        </>
      )}
      
      { role === 'admin' && (
        <ReferenceInput
          source="center_id"
          reference="centers"
        >
          <AutocompleteInput
            label="Centre"
            optionText="name"
            filterToQuery={searchText => filterBlankSpaces(searchText)}
            onChange={(value, event) => setChosenCenter(value)}
            validate={[required()]}
            variant="outlined"
            fullWidth
          />
        </ReferenceInput>
      )}

      <ReferenceInput
        source="type_id"
        reference="tasks_types"
      >
        <AutocompleteInput
          label="Type de tâche"
          optionText="name"
          filterToQuery={searchText => filterBlankSpaces(searchText)}
          validate={[required()]}
          variant="outlined"
          fullWidth
        />
      </ReferenceInput>

      <TextInput label="Intitulé" source="name" validate={[required()]} variant="outlined" autoFocus fullWidth />
      
      <Typography variant="h6" color="textSecondary" sx={{ mt: 2, mb: 2 }}>Description de la tâche</Typography>

      <RichTextInput
        label={false}
        source="description"
        validate={[required()]}
        sx={{ '& .RaRichTextInput-editorContent #description': { minHeight: '200px !important' } }}
        fullWidth
      />

      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <DateInput label="Début" source="start_at" validate={[required()]} variant="outlined" fullWidth />
        </Grid>

        <Grid item xs={6} md={6}>
          <DateInput label="Fin" source="end_at" variant="outlined" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}