import React, { useEffect, useState } from 'react'
import {
  useListContext,
  useGetIdentity,
  useRefresh,
  useRedirect,
} from 'react-admin'
import {
  Button,
  Grid,
  Autocomplete,
  TextField,
  Stack
} from '@mui/material'

import GetData from '../../common/GetData'
import { getRole } from '../../common/roles'
import createGroupFilters from './groupFilters'
import { supabaseClient } from '../../supabase'
import fetchEvents from '../../common/fetchEvents'
import { CommonDialog } from '../../common/Dialog'
import { classes as optionClasses } from '../../common/classes'
import fetchEventsQualifications from '../../common/fetchEventsQualifications'
import { getItemFromLocalStorage, setItemToLocalStorage } from '../../common/LocalStorage'

import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const CenterFilter = ({ centersData, identity, getRole, AddFilterToCurrentList, method }) => {
  const centerChoices = centersData?.map(center => ({
    label: center.name,
    value: {
      name: center.name,
      id: center.id,
    }
  })) || []

  // Optionally add the 'Sans centre' option for admins
  if (identity?.role?.grade === getRole('admin')) {
    centerChoices.push({label: 'Sans centre', value: { name: 'Sans centre', id: '9999' }})
  }

  return (
    <Autocomplete
      disablePortal
      options={centerChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => AddFilterToCurrentList(data, 'center_id', 'Centre', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir un centre" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const EventFilter = ({ eventsData, centerIdToNameMap, identity, getRole, setOptionChoice, method }) => {
  const eventChoices = eventsData?.map(event => ({
    id: event.id,
    label: identity?.role?.grade === getRole('admin')
      ? `${event.name} (${new Date(event.date).toLocaleDateString()}) (${centerIdToNameMap[event.center_id] ?? 'Centre inconnu'})`
      : `${event.name} (${new Date(event.date).toLocaleDateString()})`,
    value: {
      id: event.id,
      name: event.name,
    }
  })) || []

  // Optionally add the 'Sans centre' option for admins
  if (identity?.role?.grade === getRole('admin')) {
    eventChoices.push({label: 'Sans événement', value: { name: 'Sans événement', id: '9999' }})
  }

  return (
    <Autocomplete
      disablePortal
      options={eventChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event, data) => setOptionChoice({ data, eventId: 'event_ids', eventLabel: 'Événement', method, type: 'event' })}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir un événement" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const EventTypesFilter = ({ data, identity, getRole, setOptionChoice, method }) => {
  const eventTypesChoices = data?.map(event => ({
    label: event.name,
    value: {
      name: event.name,
      id: event.id,
    }
  })) || []

  // Optionally add the 'Sans centre' option for admins
  if (identity?.role?.grade === getRole('admin')) {
    eventTypesChoices.push({label: 'Sans catégorie d\'événement', value: { name: 'Sans type', id: '9999' }})
  }

  return (
    <Autocomplete
      disablePortal

      options={eventTypesChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => setOptionChoice({ data, eventId: 'event_types', eventLabel: 'Cat. événement', method, type: 'event_type' })}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir une catégorie d'événement" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const EventsQualificationsFilter = ({ data, identity, getRole, AddFilterToCurrentList, method, optionChoice = false }) => {
  const [eventsQualificationsChoicesFiltered, setEventsQualificationsChoicesFiltered] = useState([])
  const [eventTypesQualificationsChoicesFiltered, setEventTypesQualificationsChoicesFiltered] = useState(null)

  const getQualificationForSpecificEvent = async () => {
    const { data, error } = await supabaseClient
      .from('events')
      .select('qualifications_ids')
      .eq('id', optionChoice?.data?.id)

    if (error) {
      console.error('Error fetching qualifications for event: ', error)
      return
    }

    setEventsQualificationsChoicesFiltered(data[0]?.qualifications_ids)
  }

  const getQualificationForSpecificEventType = async () => {
    const { data, error } = await supabaseClient
      .from('types')
      .select('id')
      .eq('id', optionChoice?.data?.value?.id)

    if (error) {
      console.error('Error fetching qualifications for event: ', error)
      return
    }

    setEventTypesQualificationsChoicesFiltered(data[0]?.id)
  }

  let filteredQualifications
  if ( optionChoice && optionChoice.type === 'event' ) {
    getQualificationForSpecificEvent()
    filteredQualifications = data?.filter(qual => eventsQualificationsChoicesFiltered?.includes(qual.id))
  }

  if ( optionChoice && optionChoice.type === 'event_type' ) {
    getQualificationForSpecificEventType()
    filteredQualifications = data?.filter(qual => eventTypesQualificationsChoicesFiltered?.includes(qual.type_id.id))
  }

  const eventsQualificationsChoices = (
    optionChoice
      ? optionChoice.type === 'event'
        ? eventsQualificationsChoicesFiltered?.length > 0 ? filteredQualifications : data
        : eventTypesQualificationsChoicesFiltered ? filteredQualifications : data
      : data
    ).map(qual => ({
    label: `${qual.name} (${qual.type_id?.name})`,
    value: {
      name: qual.name,
      id: qual.id,
    }
  })) || []

  if ( identity?.role?.grade === getRole('admin') ) {
    eventsQualificationsChoices.push({label: 'Sans qualification d\'événement', value: { name: 'Sans qualification', id: '9999' }})
  }

  return (
    <Autocomplete
      disablePortal
      options={
        optionChoice
          ? optionChoice.type === 'event'
            ? !eventsQualificationsChoicesFiltered ? [] : eventsQualificationsChoices
            : !eventTypesQualificationsChoicesFiltered ? [] : eventsQualificationsChoices
          : eventsQualificationsChoices
      }
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => {
        if ( ! event || ! data ) return

        if ( optionChoice ) {
          AddFilterToCurrentList(optionChoice.data, optionChoice.eventId, optionChoice.eventLabel, method, false) // closePanel = false, to not close the dialog
        }

        AddFilterToCurrentList(data, 'events_qualifications', 'Qual. d\'événement', method)
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir une qualification d'événement" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const ClassesFilter = ({ classesData, identity, getRole, AddFilterToCurrentList, method }) => {
  const classesChoices = classesData?.map(classe => ({
    label: classe.name,
    value: {
      name: classe.name,
      id: classe.id,
    }
  })) || []

  // Optionally add the 'Sans centre' option for admins
  if (identity?.role?.grade === getRole('admin')) {
    classesChoices.push({label: 'Sans classe', value: { name: 'Sans classe', id: '9999' }})
  }

  return (
    <Autocomplete
      disablePortal
      options={classesChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => AddFilterToCurrentList(data, 'class_id', 'Classe', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir une classe" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const SchoolsFilter = ({ schoolsData, centerIdToNameMap, AddFilterToCurrentList, method }) => {
  const schoolsChoices = schoolsData?.map(event => ({
    id: event.id,
    label: event.name + ' (' + (centerIdToNameMap[event.center_id] ?? 'Centre inconnu') + ')',
    value: {
      name: event.name,
      id: event.id,
    }
  })) || []

  schoolsChoices.push({label: 'Sans lycée', value: { name: 'Sans école', id: '9999' }})

  return (
    <Autocomplete
      disablePortal
      options={schoolsChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => AddFilterToCurrentList(data, 'school_id', 'Lycée', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir un lycée" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const QualificationsFilter = ({ statutsInscriptionData, AddFilterToCurrentList, method }) => {
  const statusChoices = statutsInscriptionData?.map(statut => ({
    label: statut.name,
    value: {
      name: statut.name,
      id: statut.id,
    }
  })) || []

  statusChoices.push({label: 'Sans statut', value: { name: 'Sans statut', id: '9999' }})

  return (
    <Autocomplete
      disablePortal
      options={statusChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => AddFilterToCurrentList(data, 'status_inscription_id', 'Statut Inscr.', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir un statut" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const TagsFilter = ({ tagsIdsData, AddFilterToCurrentList, method }) => {
  const tagsChoices = tagsIdsData?.map(tag => ({
    label: tag.name,
    value: {
      name: tag.name,
      id: tag.id,
    }
  })) || []

  tagsChoices.push({label: 'Sans tag', value: { name: 'Sans tag', id: '9999' }})

  return (
    <Autocomplete
      disablePortal
      options={tagsChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => AddFilterToCurrentList(data, 'tags_ids', 'Tags', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir un tag" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const TasksFilter = ({ tasksIdsData, AddFilterToCurrentList, method }) => {
  const tasksChoices = tasksIdsData?.map(task => ({
    label: task.name,
    value: {
      name: task.name,
      id: task.id,
    }
  })) || []

  tasksChoices.push({label: 'Sans tâche', value: { name: 'Sans tâche', id: '9999' }})

  return (
    <Autocomplete
      disablePortal
      options={tasksChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => AddFilterToCurrentList(data, 'tasks_info', 'Tâches', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir une tâche" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const TasksStatusFilter = ({ AddFilterToCurrentList, method }) => {
  const tasksStatus = [
    {
      label: 'Non commencée',
      value: {
        name: 'Non commencée',
        id: 0,
      }
    },
    {
      label: 'En cours',
      value: {
        name: 'En cours',
        id: 33,
      }
    },
    {
      label: 'En attente',
      value: {
        name: 'En attente',
        id: 45,
      }
    },
    {
      label: 'Fin d\'attente',
      value: {
        name: 'Fin d\'attente',
        id: 55,
      }
    },
    {
      label: 'Terminée',
      value: {
        name: 'Terminée',
        id: 100,
      }
    },
  ]

  return (
    <Autocomplete
      disablePortal
      options={tasksStatus}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event,data) => AddFilterToCurrentList(data, 'tasks_info', 'Avancement des tâches', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir une tâche" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const LeadSourceFilter = ({ sourcesData, identity, getRole, AddFilterToCurrentList, method }) => {
  const sourcesChoices = sourcesData?.map(source => ({
    label: source.name,
    value: {
      name: source.name,
      id: source.id,
    }
  })) || []

  // Optionally add the 'Sans centre' option for admins
  if (identity?.role?.grade === getRole('admin')) {
    sourcesChoices.push({label: 'Sans origine', value: { name: 'Sans origine', id: '9999' }})
  }

  return (
    <Autocomplete
      disablePortal
      options={sourcesChoices}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      autoComplete
      includeInputInList
      sx={{ minWidth: '100%' }}
      onChange={(event, data) => AddFilterToCurrentList(data, 'lead_source_id', 'Origine', method)}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir une origine" />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  )
}

const FILTER_COMPONENTS = {
  'center_id': CenterFilter,
  'event_ids': EventFilter,
  'event_types': EventTypesFilter,
  'events_qualifications': EventsQualificationsFilter,
  'class_id': ClassesFilter,
  'school_id': SchoolsFilter,
  'status_inscription_id': QualificationsFilter,
  'tags_ids': TagsFilter,
  'tasks_info': TasksFilter,
  'tasks_status': TasksStatusFilter,
  'lead_source_id': LeadSourceFilter,
}

const AddFilterDialog = ({ groupNumber = 1, groupCondition = 'AND', styles, label, color = 'primary', variant = 'outlined', icon, isMainButton = true }) => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const { filterValues, setFilters } = useListContext()

  const [isOpen, setIsOpen] = useState(false)
  const [method, setMethod] = useState(null)
  const [filterChoice, setFilterChoice] = useState(null)
  const [optionChoice, setOptionChoice] = useState(null)
  const [getEventQualification, setGetEventQualification] = useState(null)
  const [listChoice, setListChoice] = useState(null)

  const localCurrentYear = getItemFromLocalStorage('localCurrentYear')?.id || process.env.REACT_APP_CURRENT_YEAR_ID
  const isAdmin = identity?.role?.grade === getRole('admin') || false
  const localCenter = isAdmin
    ? { id: '0000', name: 'Tous les centres' }
    : identity?.center
  const currentFilters = getItemFromLocalStorage('groupedFilteredValues') ?? null
  const centersData = GetData('centers', '50', 'name', 'ASC')
  const sourcesData = GetData('lead_sources', '50', 'name', 'ASC')
  const eventTypesData = GetData('types', '9999', 'name', 'ASC')
  const schoolsData = GetData('schools', '9999', 'name', 'ASC')
  const statutsInscriptionData = GetData('qualifications', '50', 'name', 'ASC')
  const tagsIdsData = GetData('tags', '50', 'name', 'ASC', isAdmin || !identity?.center?.id ? { year_id: localCurrentYear } : { center_id: identity?.center?.id, year_id: localCurrentYear })
  const tasksIdsData = GetData('tasks', '999', 'name', 'ASC', isAdmin || !identity?.center?.id ? { year_id: localCurrentYear } : { center_id: identity?.center?.id, year_id: localCurrentYear })
  const [eventsData, setEventsData] = useState([])
  const [eventsQualificationsData, setEventsQualificationsData] = useState([])
  const [centerIdToNameMap, setCenterIdToNameMap] = useState([])

  useEffect(() => {
    const getEvents = async () => {
      const centerToFilter = filterValues?.center_id ?? localCenter?.id
      const catToFilter = filterValues?.cype_id ?? null
      
      const data = await fetchEvents(centerToFilter, identity, false, catToFilter, localCurrentYear)

      let filteredData = data
      if (currentFilters) { // Check if there are any filters
        // Iterate over all groups to find event_types filter
        currentFilters.forEach(group => {
          if (!group || !group.groupFilters) return

          const filters = group.groupFilters
          
          if (typeof filters === 'object') {
            Object.keys(filters).forEach(key => {
              const filterGroup = filters[key]

              Object.values(filterGroup).forEach(filter => {
                if (filter.filterLabel === 'Cat. événement' && filter.filterId) {
                  filteredData = filteredData.filter(event => event.type_id === filter.filterId)
                }
              })
            })
          }
        })
      }

      if ( filterValues?.center_id ) {
        // filtering by center id if admin is already filtering by center
        filteredData = filteredData.filter(event => event.center_id === filterValues?.center_id)
      }

      setEventsData(filteredData)
    }

    const getEventsQualificationsData = async () => {
      const centerToFilter = filterValues?.center_id ?? localCenter?.id
      const catToFilter = filterValues?.type_id ?? null

      const data = await fetchEventsQualifications(identity, centerToFilter, catToFilter, localCurrentYear)

      setEventsQualificationsData(data)
    }

    getEvents()
    getEventsQualificationsData()
  }, [filterChoice])

  useEffect(() => {
    const GetCentersNames = async () => {
      const centerIdToNameMap = centersData?.reduce((map, center) => {
        map[center.id] = center.name
        return map
      }, {})

      setCenterIdToNameMap(centerIdToNameMap)
    }

    GetCentersNames()
  }, [filterChoice])

  // const events = eventsData?.filter(event => {
  //   return ( getRole('admin') < identity?.role?.grade && event.center_id !== identity?.center_id )
  //     ? false
  //     : true
  // })

  let listOfFiltersToChoose = [
    { label: 'Classe',                value: { name: 'class_id',                type: 'list' } },
    { label: 'Événement',             value: { name: 'event_ids',               type: 'list' } },
    { label: 'Cat. évènement',        value: { name: 'event_types',             type: 'list' } },
    { label: 'Qual. d\'évènements',   value: { name: 'events_qualifications',   type: 'list' } },
    { label: 'Origine du lead',       value: { name: 'lead_source_id',          type: 'list' } },
    { label: 'Tâches',                value: { name: 'tasks_info',              type: 'list' } },
    { label: 'Avancement des tâches', value: { name: 'tasks_status',            type: 'list' } },
    { label: 'Tags',                  value: { name: 'tags_ids',                type: 'list' } },
    { label: 'Statuts Inscription',   value: { name: 'status_inscription_id',   type: 'list' } },
    { label: 'Intéressé',             value: { name: 'interested',              type: 'bool' } },
    { label: 'Pas intéressé',         value: { name: 'not_interested',          type: 'bool' } },
    { label: 'Lycée',                 value: { name: 'school_id',               type: 'list' } },
  ]

  const getDataForFilterType = (name) => {
    switch (name) {
      case 'center_id': return { centersData }
      case 'event_ids': return { eventsData }
      case 'event_types': return { data: eventTypesData }
      case 'events_qualifications': return { data: eventsQualificationsData }
      case 'class_id': return { classesData: optionClasses[localCenter?.id]?.options ?? optionClasses['default']?.options }
      case 'school_id': return { schoolsData }
      case 'status_inscription_id': return { statutsInscriptionData }
      case 'tags_ids': return { tagsIdsData }
      case 'tasks_info': return { tasksIdsData }
      case 'lead_source_id': return { sourcesData }
      default: return {}
    }
  }

  const handleAddFilter = () => setIsOpen(true)

  const AddFilterToCurrentList = (filterInfo, name, label, method, closePanel = true) => {
    const groupColors = [
      '#faf6d9',
      '#f3eff0',
      '#e0d2ef',
      '#f5f9d2',
      '#eaf0e6',
      '#f3eff0',
      '#e0d2ef',
      '#f5f9d2',
      '#ccc',
      '#eee',
      '#fff',
      '#fefefe',
      '#cdcdcd',
      '#cecece'
    ]

    const filterOptions = {
      filterId: filterInfo?.value?.id ?? null,
      filterTitle: filterInfo?.value?.name ?? null,
      filterLabel: label ?? filterInfo?.value?.name ?? null,
      filterMethod: method ?? true,
      filterGroup: groupNumber,
      groupCondition: groupCondition,
      groupColor: groupColors[groupNumber - 1],
    }

    const filterValuesBeforeGrouped = getItemFromLocalStorage('filterValuesBeforeGrouped') ?? null

    let newFilterType = {}
    if ( filterValuesBeforeGrouped ) {
      newFilterType = filterValuesBeforeGrouped[name] ? { ...filterValuesBeforeGrouped[name] } : {}
    }

    const filterCounter = Object.keys(newFilterType).length + 1
    
    newFilterType[filterCounter] = filterOptions

    const updatedFilterValuesBeforeGroup = {
      ...filterValuesBeforeGrouped,
      [name]: newFilterType,
    }

    setItemToLocalStorage('filterValuesBeforeGrouped', updatedFilterValuesBeforeGroup)

    const groupedFilteredValues = createGroupFilters(updatedFilterValuesBeforeGroup)
    setItemToLocalStorage('groupedFilteredValues', groupedFilteredValues)
    setItemToLocalStorage('groupedFilteredCondition', 'AND')
    setItemToLocalStorage('betweenGroupsCondition', 'or')

    if ( closePanel ) {
      setMethod(null)
      setFilterChoice(null)
      setListChoice(null)
      setIsOpen(false)
      redirect('/leads')
      setFilters({ ...filterValues, 'groupFilters': 'ok' })
      refresh()
    }
  }

  return (
    <>
      <CommonDialog
        open={isOpen}
        handleClose={() => {
          setIsOpen(false)
          setMethod(null)
          setFilterChoice(null)
          setOptionChoice(null)
          setListChoice(null)
          setGetEventQualification(null)
        }}
        icon={icon}
        title="Ajouter un filtre"
        size="xs"
        minHeight="60vh"
      >
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="success"
              fullWidth
              onClick={() => setMethod(true) }
              disabled={method !== null ? true : false}
            >
              Inclure
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="warning"
              fullWidth
              onClick={() => setMethod(false) }
              disabled={method !== null ? true : false}
            >
              Exclure
            </Button>
          </Grid>
        </Grid>

        
        { method !== null && (
          <Autocomplete
            disablePortal
            options={listOfFiltersToChoose}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            autoComplete
            includeInputInList
            sx={{ minWidth: '100%', marginTop: '10px' }}
            onChange={(event, value) => {
              if ( ! value || ! event ) {
                setMethod(null)
                setFilterChoice(null)
                return
              }

              value?.value?.type === 'list'
                ? setFilterChoice(value?.value)
                : AddFilterToCurrentList('', value?.value?.name, '', method)
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir un filtre" />}
            disabled={listChoice ? true : false}
          />
        )}
        
        { filterChoice && filterChoice.type === 'list' && FILTER_COMPONENTS[filterChoice.name] && (
          React.createElement(FILTER_COMPONENTS[filterChoice.name], {
            ...getDataForFilterType(filterChoice.name),
            centerIdToNameMap,
            identity,
            getRole,
            setOptionChoice,
            optionChoice,
            method,
            AddFilterToCurrentList,
          })
        )}

        { filterChoice && ( filterChoice.name === 'event_ids' || filterChoice.name === 'event_types' ) && optionChoice && (
          <Stack spacing={1} mt={2} direction="row" justifyContent="space-between">
            <Button
              variant="outlined"
              color="success"
              onClick={() => setGetEventQualification(true)}
            >
              Ajouter un filtre tag
            </Button>

            <Button
              variant="outlined"
              color="success"
              onClick={() => AddFilterToCurrentList(optionChoice.data, optionChoice.eventId, optionChoice.eventLabel, optionChoice.method)}
            >
              Continuer
            </Button>
          </Stack>
        )}

        { filterChoice && ( filterChoice.name === 'event_ids' || filterChoice.name === 'event_types' ) && optionChoice && getEventQualification && (
          React.createElement(FILTER_COMPONENTS['events_qualifications'], {
            ...getDataForFilterType('events_qualifications'),
            centerIdToNameMap,
            identity,
            getRole,
            method,
            AddFilterToCurrentList,
            optionChoice,
            getEventQualification,
          })
        )}
      </CommonDialog>

      { icon ? (
        isMainButton ? (
          <IconButton
            sx={{ margin: '0 1vw 0 1vw' }}
            size="small"
            color="primary"
            onClick={handleAddFilter}
            aria-label={label}
            title="Ajouter un filtre dans le groupe"
          >
            {icon}
          </IconButton>
        ) : <AddCircleOutlineIcon
              style={{ fontSize: '1.2rem', cursor: 'pointer' }}
              onClick={handleAddFilter}
            />
      ) : (
        <Button
          variant={variant}
          color={color}
          onClick={handleAddFilter}
          sx={styles}
        >
          {label}
        </Button>
      )}
    </>
  )
}

export default AddFilterDialog