import { useEffect, useState } from 'react'
import { Loading, Link, useGetIdentity, TopToolbar } from 'react-admin'
import { Box, Chip, Stack, Typography, FormControl, Select as MUIselect, InputLabel, MenuItem } from '@mui/material'

import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import ConversationMessages from './ConversationMessages'
import ConversationImport from './ConversationImport'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const monthNamesFR = {
  'janvier': 0,
  'février': 1,
  'mars': 2,
  'avril': 3,
  'mai': 4,
  'juin': 5,
  'juillet': 6,
  'août': 7,
  'septembre': 8,
  'octobre': 9,
  'novembre': 10,
  'décembre': 11
}

const parseMonthString = (monthString) => {
  const [monthName, year] = monthString.toLowerCase().split(' ')
  const month = monthNamesFR[monthName]

  if (month === undefined || isNaN(year)) {
    throw new Error('Invalid month format')
  }

  return new Date(Number(year), month, 1)
}

const getPreviousMonths = (count) => {
  const months = []
  const currentDate = new Date()

  for (let i = 0; i < count; i++) {
    const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)
    months.push(monthDate.toLocaleString('fr', { month: 'long', year: 'numeric' }))
  }

  return months
}

const ConversationsPanel = () => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const [centers, setCenters] = useState(null)
  const [centerPubeurs, setCenterPubeurs] = useState(isAdmin ? null : identity?.center?.id)
  const [pubeursList, setPubeursList] = useState(null)
  const [conversationContacts, setConversationContacts] = useState(null)
  const [selectedConversation, setSelectedConversation] = useState({})
  const [pubeurFullName, setPubeurFullName] = useState(null)
  const [leadFullName, setLeadFullName] = useState(null)
  const [leadId, setLeadId] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(null)

  const handleChangeMonth = (event) => {
    console.log(event.target.value)
    setSelectedMonth(event.target.value)
  }

  const refreshConversations = () => {
    getConversationsList()
  }

  const getConversationContacts = async (pubeurId) => {
    if ( ! pubeurId ) return null

    const { data, error } = await supabaseClient
      .from('conversations_with_leads_view')
      .select('*')
      .eq('pubeur_id', pubeurId)
      .order('updated_at', { ascending: false })

    if (error) {
      console.error(error)
      return null
    }

    if ( data.length === 0 ) return null

    const conv = data[0]

    setConversationContacts(data)
    setPubeurFullName(`${conv?.pubeur_first_name} ${conv?.pubeur_last_name}`)
    setSelectedConversation(null)
  }

  const getConversationsList = async () => {
    let query = supabaseClient
      .from('conversations_with_leads_view')
      .select('*')
      .eq('center_id', centerPubeurs)

    if (selectedMonth) {
      const startOfMonth = parseMonthString(selectedMonth)
      const endOfMonth = new Date(startOfMonth)
      endOfMonth.setMonth(endOfMonth.getMonth() + 1)

      query = query
        .gte('created_at', startOfMonth.toISOString())
        .lt('created_at', endOfMonth.toISOString())
    }

    const { data, error } = await query.order('updated_at', { ascending: false })
    
    if (error) {
      console.error(error)
      return null
    }

    const uniqueConversations = data.filter((conv, index, self) =>
      index === self.findIndex((c) => (
        c.pubeur_id === conv.pubeur_id
      ))
    )

    setPubeursList(uniqueConversations)
    setConversationContacts(null)
    setSelectedConversation(null)
  }

  useEffect(() => {
    if ( !centerPubeurs) return
    
    getConversationsList()
  }, [centerPubeurs, selectedMonth])

  useEffect(() => {
    const getCenters = async () => {
      const { data, error } = await supabaseClient
        .from('centers')
        .select('id, name')
        .order('created_at', { ascending: true })
      
      if (error) {
        console.error(error)
        return null
      }

      setCenters(data)
    }

    if ( isAdmin && ! centerPubeurs ) getCenters()
    else getConversationsList()
  }, [])

  return identity ? (
    <Box sx={{ maxWidth: isAdmin ? '75%' : '90%', margin: '0 auto' }}>
      <TopToolbar>
        <ConversationImport refreshConversations={refreshConversations} />

        <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
          <InputLabel id="monthLabel">Conv. créées en...</InputLabel>
          <MUIselect
            labelId="monthLabel"
            id="select-monthLabel"
            value={selectedMonth}
            variant="outlined"
            onChange={handleChangeMonth}
            label="Archives des mois précédents"
          >
            <MenuItem value={null}>Toutes les conversations</MenuItem>
            {getPreviousMonths(12).map((monthValue, index) => (
              <MenuItem key={index} value={monthValue}>{monthValue}</MenuItem>
            ))}
          </MUIselect>
        </FormControl>
      </TopToolbar>

      <Stack direction="row" mt={0.5} spacing={1} sx={{
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;',
      }}>
        { isAdmin && <Box width="14vw" sx={{
          padding: '1em',
          paddingRight: '1em',
          minHeight: '100vh',
          boxShadow: 'rgba(0, 0, 0, 0.075) 8px 0px 8px -2px',
          backgroundColor: '#dceded'
        }}>
          <Typography variant="h6" mb={2} gutterBottom>
            Centres
          </Typography>

          { centers?.map(center => (
            <Chip
              key={center.id}
              sx={{
                display: 'block-flex',
                marginBottom: '1em',
                backgroundColor: centerPubeurs === center.id ? '#1b91b8' : '#a6d9ea',
                color: centerPubeurs === center.id ? '#fefefe' : '#121212',
                borderRadius: '0.6em',
                '&:hover': {
                  backgroundColor: '#3eb4d8',
                  color: '#fefefe',
                }
              }}
              onClick={() => setCenterPubeurs(center.id)}
              label={center.name}
            />
          )) }
        </Box> }

        { pubeursList && <Box width={ isAdmin ? '16vw' : '18vw' } sx={{
          padding: '1vw',
          paddingRight: '1vw',
          minHeight: '100vh',
          boxShadow: 'rgba(0, 0, 0, 0.075) 8px 0px 8px -2px',
          backgroundColor: '#edf5f5'
        }}>
          <Typography variant="h6" mb={2} gutterBottom>
            Pubeurs
          </Typography>

          { pubeursList?.length > 0 && pubeursList?.map(conv => (
            <Chip
              key={conv.conversation_id}
              label={
                <span
                  style={{
                    display: 'inline-block',
                    maxWidth: '14vw',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginTop: '6px'
                  }}
                >
                  {`${conv.pubeur_first_name ?? ''} ${conv.pubeur_last_name ?? ''}`}
                </span>
              }
              variant="contained"
              color="primary"
              sx={{
                display: 'block-flex',
                marginBottom: '1em',
                backgroundColor: conversationContacts && conversationContacts[0]?.pubeur_id === conv.pubeur_id ? '#1b91b8' : '#a6d9ea',
                color: conversationContacts && conversationContacts[0]?.pubeur_id === conv.pubeur_id ? '#fefefe' : '#121212',
                borderRadius: '0.6em',
                '&:hover': {
                  backgroundColor: '#3eb4d8',
                  color: '#fefefe',
                }
              }}
              onClick={() => {
                getConversationContacts(conv.pubeur_id)
                setPubeurFullName(`${conv.pubeur_first_name} ${conv.pubeur_last_name}`)
              }}
            />
          )) }

          { pubeursList?.length === 0 && <Typography variant="body1" mb={2} gutterBottom>
            Aucune conversation n'a été enregistrée dans votre centre.
          </Typography> }
        </Box> }

        { conversationContacts && pubeursList?.length > 0 && (
          <Box width={ isAdmin ? '16vw' : '18vw' } sx={{
            padding: '1vw',
            backgroundColor: '#edf5f5',
            boxShadow: 'rgba(0, 0, 0, 0.075) 8px 0px 8px -2px'
          }}>
            <Typography variant="h6" mb={2} gutterBottom>
              Conversations
            </Typography>

            { conversationContacts?.map(conv => (
              <Chip
                key={conv.conversation_id}
                label={
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: '14vw',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginTop: '6px'
                    }}
                  >
                    {`${conv.lead_first_name ?? ''} ${conv.lead_last_name ?? ''}`}
                  </span>
                }
                sx={{
                  display: 'block-flex',
                  marginBottom: '1em',
                  backgroundColor: selectedConversation?.id === conv.conversation_id ? '#1b91b8' : '#a6d9ea',
                  color: selectedConversation?.id === conv.conversation_id ? '#fefefe' : '#121212',
                  borderRadius: '0.6em',
                  '&:hover': {
                    backgroundColor: '#3eb4d8',
                    color: '#fefefe',
                  }
                }}
                onClick={() => {
                  setSelectedConversation({ id: conv.conversation_id, system: conv.system })
                  setLeadFullName(`${conv.lead_first_name ?? ''} ${conv.lead_last_name ?? ''}`)
                  setLeadId(conv.lead_id)
                }}
              />
            ))  }
          </Box>
        )}

        { pubeursList?.length > 0 && selectedConversation && <Box sx={{
          padding: '1em',
          width: isAdmin ? '45em' : '56em',
        }}>
          <Typography variant="h6" mb={2} gutterBottom>
            Conversation avec <Link to={`${process.env.REACT_APP_WEBSITE_URL}/leads/${leadId}`} target="_blank">{`${leadFullName}`} <OpenInNewIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle' }} /> </Link>
          </Typography>

          <ConversationMessages
            convId={selectedConversation.id}
            convSystem={selectedConversation.system}
            pubeurName={pubeurFullName}
            leadName={leadFullName}
            refreshConversations={refreshConversations}
          />
        </Box> }
      </Stack>
    </Box>
  ) : <Loading />
}

export default ConversationsPanel